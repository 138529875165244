export const setups = [
  {
    tags: ['trigger:tmd'],
    category: ['algo'],
    slug: 'algo:tmd-efficiency',
    i18n: 'Dedicated algorithm for Tuned Mass Damper (TMD) efficiency computation.',
    'data-sources': ['recC1RY9E9pMDfbzO'],
    _id: 'recN9iLlqCPRIqpGB'
  },
  {
    category: ['acquisition'],
    slug: 'aq:monitor-4',
    i18n: 'Monitor-4',
    'data-sources': [
      'recoPHTnfctQNZZj9',
      'recVrFS2OsKkRqW3O',
      'reczQrLzwcnhs9ai2',
      'rec4aijszCw0NNeaj',
      'recMAYnjeShJ2TU6y',
      'reciy3WiVuSK6TURe',
      'recvzchb0VfGSJgjA',
      'recGgDJqZ5HE0Vvcw',
      'recEwPg9XshyyWtu7',
      'recyMINUTVLCzz2qL',
      'recTJvvnO1tAK4kpS',
      'recBi8lw71cH5EME9',
      'recPPHY6AWuFGyh1j'
    ],
    _id: 'rec8gP81biV5ydh7e'
  },
  {
    category: ['acquisition'],
    slug: 'aq:monitor-8',
    i18n: 'Monitor-8',
    'data-sources': [
      'recLxhIA6Bnvt1xcG',
      'rec91LQaRUP2YO0zn',
      'recC1RY9E9pMDfbzO',
      'recquF6DJuIrwwJdC',
      'recOrTN86YJochoOW',
      'recVgyOlCT82uu00F',
      'reccSXMPSO5FNs5QQ',
      'recjsrR35WaSNtMvK',
      'rechGRZ9PkhxxtL8P',
      'reckEBCIbOJBz1hAV',
      'recJ06o66XKyj2lhz',
      'reczu9EMYTMbotMVP'
    ],
    _id: 'recxHVfV715agcSSL'
  },
  {
    category: ['acquisition'],
    slug: 'aq:monitor-8-i',
    i18n: 'Monitor-8-I',
    _id: 'rectc5VQwpmjuRAnY'
  },
  {
    category: ['acquisition'],
    slug: 'aq:recovib-blasting',
    i18n: 'Recovib.Blasting',
    'data-sources': ['reca6za0b0tgTht9L', 'recvJ8iTYK3juJ5h8', 'recSBNeipTz37MEqd', 'rec8P9nhHyXsIUvNX', 'recCaPYRzVpL22b5U'],
    _id: 'recO3J0JmGBHLxHFD'
  },
  {
    category: ['comm'],
    slug: 'cm:4g-board',
    i18n: '4G Board',
    'data-sources': [
      'reca6za0b0tgTht9L',
      'recLxhIA6Bnvt1xcG',
      'recvJ8iTYK3juJ5h8',
      'recSBNeipTz37MEqd',
      'rec8P9nhHyXsIUvNX',
      'recoPHTnfctQNZZj9',
      'recVrFS2OsKkRqW3O',
      'reczQrLzwcnhs9ai2',
      'rec4aijszCw0NNeaj',
      'recMAYnjeShJ2TU6y',
      'reciy3WiVuSK6TURe',
      'recvzchb0VfGSJgjA',
      'recGgDJqZ5HE0Vvcw',
      'recquF6DJuIrwwJdC',
      'recOrTN86YJochoOW',
      'recyMINUTVLCzz2qL',
      'recTJvvnO1tAK4kpS',
      'recBi8lw71cH5EME9',
      'recPPHY6AWuFGyh1j',
      'recVgyOlCT82uu00F',
      'reccSXMPSO5FNs5QQ',
      'recjsrR35WaSNtMvK',
      'rechGRZ9PkhxxtL8P',
      'reckEBCIbOJBz1hAV',
      'recJ06o66XKyj2lhz',
      'reczu9EMYTMbotMVP',
      'recCaPYRzVpL22b5U',
      'recEwPg9XshyyWtu7'
    ],
    _id: 'recOnceAH3FyM4Uj8'
  },
  {
    category: ['comm'],
    slug: 'cm:wifi',
    i18n: 'Wifi',
    'data-sources': ['recyMINUTVLCzz2qL', 'recTJvvnO1tAK4kpS', 'recBi8lw71cH5EME9', 'recPPHY6AWuFGyh1j'],
    _id: 'recOtJJKpvT37z9HP'
  },
  {
    category: ['conn'],
    slug: 'conn:i1-i1-i1-i1',
    'data-sources': ['recyMINUTVLCzz2qL', 'recTJvvnO1tAK4kpS', 'recBi8lw71cH5EME9', 'recPPHY6AWuFGyh1j'],
    _id: 'rec4t8JtY53tEO2OO'
  },
  {
    category: ['conn'],
    slug: 'conn:i2-i2-i2-i2',
    'data-sources': [
      'recVgyOlCT82uu00F',
      'reccSXMPSO5FNs5QQ',
      'recjsrR35WaSNtMvK',
      'rechGRZ9PkhxxtL8P',
      'reckEBCIbOJBz1hAV',
      'recJ06o66XKyj2lhz',
      'reczu9EMYTMbotMVP'
    ],
    _id: 'rec3DxqVytUlKlmir'
  },
  {
    category: ['conn'],
    slug: 'conn:i3',
    'data-sources': ['recSBNeipTz37MEqd', 'rec8P9nhHyXsIUvNX', 'recOrTN86YJochoOW'],
    _id: 'recIB9O3EsqDIf9W2'
  },
  {
    category: ['conn'],
    slug: 'conn:i3-i1',
    'data-sources': ['recoPHTnfctQNZZj9', 'recVrFS2OsKkRqW3O'],
    _id: 'recm4SnpwlU3q0VBm'
  },
  {
    category: ['conn'],
    slug: 'conn:i3-icp1',
    'data-sources': ['reca6za0b0tgTht9L', 'recLxhIA6Bnvt1xcG'],
    _id: 'recYZeXh7nwxqmfEp'
  },
  {
    category: ['conn'],
    slug: 'conn:ud3-u1',
    'data-sources': ['recoPHTnfctQNZZj9', 'recVrFS2OsKkRqW3O'],
    _id: 'recJdyxzz0lJ9y2kw'
  },
  {
    tags: ['ix:hosted-app'],
    category: ['deployment'],
    slug: 'host:pr202',
    i18n: 'https://pr-202-01.azurewebsites.net/',
    'data-sources': ['reca6za0b0tgTht9L', 'recSBNeipTz37MEqd', 'rec8P9nhHyXsIUvNX', 'rec91LQaRUP2YO0zn', 'recC1RY9E9pMDfbzO'],
    _id: 'recInyAZCmqwmU85K'
  },
  {
    tags: ['ix:hosted-app'],
    category: ['deployment'],
    slug: 'host:recovib-cloud-deprecated',
    i18n: 'Recovib-monitoring cloud platform (deprecated)',
    Deprecated: true,
    'data-sources': ['recoPHTnfctQNZZj9', 'recVrFS2OsKkRqW3O'],
    _id: 'recJVIKy2fu2LYuHX'
  },
  {
    tags: ['ix:hosted-app'],
    category: ['deployment'],
    slug: 'host:recovib.io',
    i18n: 'http://recovib.io',
    'data-sources': [
      'recLxhIA6Bnvt1xcG',
      'recquF6DJuIrwwJdC',
      'recyMINUTVLCzz2qL',
      'recTJvvnO1tAK4kpS',
      'recBi8lw71cH5EME9',
      'recPPHY6AWuFGyh1j',
      'recCaPYRzVpL22b5U'
    ],
    _id: 'recEqzCEbFib3PBwt'
  },
  {
    tags: ['type:1-axis'],
    category: ['sensor'],
    slug: 'in:1-axis-cm-accelero',
    i18n: '1-axis CM accelerometer',
    'data-sources': ['recyMINUTVLCzz2qL', 'recTJvvnO1tAK4kpS', 'recBi8lw71cH5EME9', 'recPPHY6AWuFGyh1j'],
    _id: 'rec0dgLaShzKrLDcr'
  },
  {
    tags: ['type:1-axis'],
    category: ['sensor'],
    slug: 'in:1-axis-iac-i-accelero',
    i18n: 'standard IAC-I 1-axis accelerometer',
    _id: 'recC0TvZQzzBWAvz8'
  },
  {
    tags: ['type:2-axis'],
    category: ['sensor'],
    slug: 'in:2-axis-iac-i-accelero',
    i18n: 'IAC-I 2-axis accelerometer',
    'data-sources': [
      'recVgyOlCT82uu00F',
      'reccSXMPSO5FNs5QQ',
      'recjsrR35WaSNtMvK',
      'rechGRZ9PkhxxtL8P',
      'reckEBCIbOJBz1hAV',
      'recJ06o66XKyj2lhz',
      'reczu9EMYTMbotMVP'
    ],
    _id: 'recBRoBRRv61gmCvT'
  },
  {
    tags: ['type:3-axis', 'type:multi-series', 'trigger:accelero'],
    category: ['sensor'],
    slug: 'in:3-axis-hres-i-accelero',
    i18n: '3-axis HiRes I accelerometer ',
    'data-sources': ['reca6za0b0tgTht9L', 'recvJ8iTYK3juJ5h8', 'recSBNeipTz37MEqd', 'rec8P9nhHyXsIUvNX', 'recoPHTnfctQNZZj9', 'rec91LQaRUP2YO0zn'],
    _id: 'recciR5HmT4NnwUul'
  },
  {
    tags: ['type:3-axis', 'type:multi-series', 'trigger:accelero'],
    category: ['sensor'],
    slug: 'in:3-axis-hres-ud-accelero',
    i18n: '3-axis HiRes Ud accelerometer',
    'data-sources': ['reca6za0b0tgTht9L', 'recvJ8iTYK3juJ5h8', 'recSBNeipTz37MEqd', 'rec8P9nhHyXsIUvNX', 'recCaPYRzVpL22b5U'],
    _id: 'recC1Xr5JFgo0EMzy'
  },
  {
    tags: ['trigger:air-pressure'],
    category: ['sensor'],
    slug: 'in:air-pressure-infrasound-mic-2-250hz',
    i18n: 'Air Pressure Microphone (infrasound from 2Hz to 250Hz).',
    'data-sources': ['recLxhIA6Bnvt1xcG'],
    _id: 'recjVflqraPd3OJrv'
  },
  {
    tags: ['trigger:dust'],
    category: ['sensor'],
    slug: 'in:dust-meter',
    i18n: 'Casella dust meter with U/I external converter ',
    'data-sources': ['recVrFS2OsKkRqW3O'],
    _id: 'recL9iJ5j0ThDn69x'
  },
  {
    tags: ['trigger:sns'],
    category: ['sensor'],
    slug: 'in:high-res-sns',
    i18n: 'HiRes Structural Noise Sensor',
    _id: 'recDzkwrjhgPHhGxg'
  },
  {
    tags: ['trigger:slope'],
    category: ['sensor'],
    slug: 'in:inclinometer',
    i18n: 'inclinometer (without temperature compensation). ',
    _id: 'reczp5OT0LG3K3mwJ'
  },
  {
    tags: ['trigger:voltage'],
    category: ['sensor'],
    slug: 'in:potentiometer-sensor',
    i18n: 'String Potentiometer',
    _id: 'recYs4tg8S4r7vVdj'
  },
  {
    tags: ['trigger:temperature'],
    category: ['sensor'],
    slug: 'in:temperature-sensor',
    i18n: 'Temperature Sensor',
    _id: 'recTQj3b0QOYiCj9z'
  },
  {
    tags: ['ix:email', 'type:alarm', 'ix:alert'],
    category: ['tlm-hook'],
    slug: 'info:alert-alarm-email',
    i18n: 'Alarm email when acceleration exceed a given level in order to stop work. ',
    'data-sources': ['reca6za0b0tgTht9L', 'recLxhIA6Bnvt1xcG', 'recoPHTnfctQNZZj9', 'recVrFS2OsKkRqW3O', 'rec91LQaRUP2YO0zn', 'recC1RY9E9pMDfbzO'],
    _id: 'recKXkaMCsW3OvTO1'
  },
  {
    tags: ['ix:sms', 'type:alarm', 'ix:alert'],
    category: ['tlm-hook'],
    slug: 'info:alert-alarm-sms',
    i18n: 'Alarm SMS when acceleration exceed a given level in order to stop work. ',
    'data-sources': ['reca6za0b0tgTht9L', 'recLxhIA6Bnvt1xcG', 'recoPHTnfctQNZZj9', 'recVrFS2OsKkRqW3O', 'rec91LQaRUP2YO0zn', 'recC1RY9E9pMDfbzO'],
    _id: 'recqOpBVBW3dRJou9'
  },
  {
    tags: ['ix:alert', 'type:burst-data'],
    category: ['tlm-hook'],
    slug: 'info:alert-burst-datapoints',
    i18n: 'Periodic bursts for FFT computations ',
    'data-sources': ['reca6za0b0tgTht9L', 'recLxhIA6Bnvt1xcG', 'recC1RY9E9pMDfbzO'],
    _id: 'recZkkeVpMkqArX0u'
  },
  {
    tags: ['ix:email', 'type:warning', 'ix:alert'],
    category: ['tlm-hook'],
    slug: 'info:alert-warning-email',
    i18n: 'Warning email when acceleration exceed a given level in order to take actions ',
    'data-sources': ['reca6za0b0tgTht9L', 'recLxhIA6Bnvt1xcG', 'recoPHTnfctQNZZj9', 'recVrFS2OsKkRqW3O', 'recC1RY9E9pMDfbzO'],
    _id: 'recMvlAtd6vPzCsQE'
  },
  {
    tags: ['ix:sms', 'type:warning', 'ix:alert'],
    category: ['tlm-hook'],
    slug: 'info:alert-warning-sms',
    i18n: 'Warning SMS when acceleration exceed a given level in order to take actions ',
    'data-sources': ['reca6za0b0tgTht9L', 'recLxhIA6Bnvt1xcG', 'recoPHTnfctQNZZj9', 'recVrFS2OsKkRqW3O', 'rec91LQaRUP2YO0zn', 'recC1RY9E9pMDfbzO'],
    _id: 'recJQXY4iUPhZMRQV'
  },
  {
    category: ['power'],
    slug: 'pow:pv-power-supply',
    i18n: ' PV power supply to avoid crossing cables',
    'data-sources': ['reczQrLzwcnhs9ai2', 'rec4aijszCw0NNeaj', 'recMAYnjeShJ2TU6y', 'reciy3WiVuSK6TURe', 'recvzchb0VfGSJgjA', 'recGgDJqZ5HE0Vvcw'],
    _id: 'recvXjSEG11yeQlDc'
  },
  {
    category: ['risk'],
    slug: 'risk:building-damage',
    i18n: 'Building Damage',
    'data-sources': ['reca6za0b0tgTht9L', 'recLxhIA6Bnvt1xcG'],
    _id: 'rec2Zn98UtyJKuUMN'
  },
  {
    tags: ['config:sensitive'],
    category: ['risk'],
    slug: 'risk:sensitive-equipment',
    i18n: 'Sensitive Equipment',
    'data-sources': ['recvJ8iTYK3juJ5h8', 'rec91LQaRUP2YO0zn'],
    _id: 'recdrW9Sa6UeqYXrI'
  },
  {
    tags: ['config:sensitive'],
    category: ['risk'],
    slug: 'risk:work-of-art',
    i18n: 'Work of Art',
    'data-sources': ['recoPHTnfctQNZZj9', 'recVrFS2OsKkRqW3O'],
    _id: 'rec7TaUagTqP1LtJr'
  },
  {
    tags: ['threshold:35pa', 'trigger:air-pressure'],
    category: ['standard'],
    slug: 'sd:air-pressure-35pa',
    i18n: 'Air pressure threshold at 35Pa',
    'data-sources': ['recLxhIA6Bnvt1xcG'],
    _id: 'recTreWvq0Er2W49U'
  },
  {
    tags: ['trigger:dust'],
    category: ['standard'],
    slug: 'sd:dust-x',
    i18n: 'Dust',
    'data-sources': ['recVrFS2OsKkRqW3O'],
    _id: 'recwptm37119EI4oz'
  },
  {
    tags: ['trigger:voltage'],
    category: ['standard'],
    slug: 'sd:potentiometer-level',
    i18n: 'Potentiometer Level',
    'data-sources': ['reczQrLzwcnhs9ai2', 'rec4aijszCw0NNeaj', 'recMAYnjeShJ2TU6y'],
    _id: 'recNRjUptB77u6OYG'
  },
  {
    tags: ['trigger:sns'],
    category: ['standard'],
    slug: 'sd:sns',
    i18n: 'SNS',
    _id: 'recy9AKNfTMhIW5Tk'
  },
  {
    tags: ['trigger:temperature'],
    category: ['standard'],
    slug: 'sd:temperature-level',
    i18n: 'Temperature Level',
    'data-sources': ['reciy3WiVuSK6TURe', 'recvzchb0VfGSJgjA', 'recGgDJqZ5HE0Vvcw'],
    _id: 'recmYuW3p3rERtVZt'
  },
  {
    tags: ['trigger:tmd'],
    category: ['standard'],
    slug: 'sd:tmd-efficiency',
    i18n: 'TMD efficiency',
    _id: 'recdC2GiWxXBgY903'
  },
  {
    tags: ['threshold:din4150', 'trigger:vibration'],
    category: ['standard'],
    slug: 'sd:vib-din4150-3-L2',
    i18n: 'Monitoring Building Vibration according to DIN4150-3/L2 (normal buildings)',
    'data-sources': ['reca6za0b0tgTht9L', 'recSBNeipTz37MEqd', 'recCaPYRzVpL22b5U'],
    _id: 'rec8k1Juv9k4Napbv'
  },
  {
    tags: ['threshold:din4150', 'trigger:vibration'],
    category: ['standard'],
    slug: 'sd:vib-din4150-3-L3',
    i18n: 'Monitoring Building Vibration according to DIN4150-3/L3 (sensitive buildings).',
    'data-sources': ['rec8P9nhHyXsIUvNX', 'recoPHTnfctQNZZj9', 'rec91LQaRUP2YO0zn'],
    _id: 'rectkjHBNrIq8bjX4'
  },
  {
    tags: ['trigger:vibration'],
    category: ['standard'],
    slug: 'sd:vib-level-monitoring',
    i18n: 'Monitoring Vibration thresholds for Warning and Alarms (actions vs stop work)',
    'data-sources': ['reca6za0b0tgTht9L', 'recvJ8iTYK3juJ5h8', 'recSBNeipTz37MEqd', 'rec8P9nhHyXsIUvNX', 'recoPHTnfctQNZZj9'],
    _id: 'recQcTeyazga3IYdm'
  },
  {
    tags: ['where:outdoors'],
    category: ['environnement'],
    slug: 'site:construction-work',
    i18n: 'Construction Work ',
    'data-sources': ['recoPHTnfctQNZZj9', 'recVrFS2OsKkRqW3O', 'rec91LQaRUP2YO0zn'],
    _id: 'recDTEN1OoEBpmj9z'
  },
  {
    tags: ['where:indoors'],
    category: ['environnement'],
    slug: 'site:hospital',
    i18n: 'Hospital',
    'data-sources': ['rec91LQaRUP2YO0zn'],
    _id: 'recP2Ub2YEUB4l2Ss'
  },
  {
    tags: ['where:indoors'],
    category: ['environnement'],
    slug: 'site:museum',
    i18n: 'Museum',
    'data-sources': ['recoPHTnfctQNZZj9', 'recVrFS2OsKkRqW3O'],
    _id: 'recxcTak7rBuOMSdh'
  },
  {
    tags: ['where:outdoors'],
    category: ['environnement'],
    slug: 'site:quarry',
    i18n: 'Quarry',
    'data-sources': ['reca6za0b0tgTht9L', 'recLxhIA6Bnvt1xcG'],
    _id: 'rec3m32p5wq4un9We'
  },
  {
    tags: ['where:outdoors'],
    category: ['environnement'],
    slug: 'site:rails',
    i18n: 'Rails',
    _id: 'recvt6mgdaIGZpSEw'
  },
  {
    tags: ['where:outdoors'],
    category: ['environnement'],
    slug: 'site:recreation-park',
    i18n: 'Recreation Park',
    'data-sources': ['recC1RY9E9pMDfbzO'],
    _id: 'recJ8OcWfMyhzxyQl'
  },
  {
    category: ['trigger'],
    slug: 'trigger:quarry-blasting',
    i18n: 'Quarry Blasting',
    'data-sources': ['reca6za0b0tgTht9L', 'recLxhIA6Bnvt1xcG'],
    _id: 'recCYQp3L7J4vA8Gp'
  },
  {
    tags: ['type:time-series'],
    category: ['tlm-unit'],
    slug: 'unit:mm/s',
    i18n: 'Millimeters per second',
    'data-sources': ['reca6za0b0tgTht9L'],
    _id: 'recDiDqnuQ5Q5JAk9'
  },
  {
    tags: ['type:time-series'],
    category: ['tlm-unit'],
    slug: 'unit:Pa',
    i18n: 'Pascal',
    'data-sources': ['recLxhIA6Bnvt1xcG'],
    _id: 'recsOtJyHlHQDljF1'
  },
  {
    category: ['domain'],
    slug: 'monitor:structural',
    i18n: 'Structural Monitoring',
    _id: 'recq9XfSDUJLzYBpm'
  }
]
