import React from 'react'
import Chart from 'react-apexcharts'

const roundDec = (d, pow = 2) => {
  const dec = Math.pow(10, pow)
  return Math.round(d * dec) / dec
}

const defaults = {
  title: 'Peak Air Pressure (Pa)',
  colors: ['#008FFB'],
  tooltipValue: function(value) {
    return value.toFixed(5)
  },
  axisValue: function(value) {
    return value.toFixed(1)
  }
}

const optionsMain = {
  chart: {
    id: 'airpressureMain',
    type: 'line',
    toolbar: {
      autoSelected: 'zoom',
      show: true
    }
  },
  brush: {
    enabled: false
  },

  title: {
    text: defaults.title,
    align: 'middle',
    offsetX: 0
  },
  animations: {
    enabled: false
  },
  markers: {
    size: 0
  },
  dataLabels: {
    enabled: false
  },
  colors: defaults.colors,
  stroke: {
    width: 1
  },
  tooltip: {
    enabled: true,
    interesect: false,
    fillSeriesColor: false,
    style: { fontSize: '0.6rem' },
    x: {
      show: true,
      format: 'dd MMM HH:mm:ss',
      formatter: undefined
    },
    y: {
      formatter: defaults.tooltipValue,
      title: {
        formatter: d => d
      }
    }
  },
  xaxis: {
    type: 'datetime',
    tooltip: {
      enabled: false
    }
  },
  yaxis: {
    min: 0,
    labels: {
      show: true,
      formatter: defaults.axisValue
    }
  },
  annotations: {
    yaxis: []
  }
}
const optionsBrush = {
  chart: {
    id: 'airpressureBrush',
    type: 'line',
    brush: {
      target: 'airpressureMain',
      enabled: true
    },
    animations: {
      enabled: false
    },
    markers: {
      size: 0
    },
    selection: {
      enabled: true
    }
  },
  colors: defaults.colors,
  stroke: {
    width: 1
  },
  fill: {
    opacity: 0.5
  },
  tooltip: {
    enabled: false
  },
  x: {
    show: true,
    format: 'dd MMM',
    formatter: undefined
  },
  y: {
    formatter: function(value) {
      return value.toFixed(5)
    },
    title: {
      formatter: seriesName => seriesName
    }
  },
  xaxis: {
    type: 'datetime'
  },
  yaxis: {
    tickAmount: 2,
    min: 0,
    labels: {
      show: true,
      formatter: function(value) {
        return value.toFixed(1)
      }
    }
  }
}

const AirpressureTimeseries = props => {
  const { datapoints, maxPoint } = props
  if (!datapoints) {
    return null
  }

  optionsMain.yaxis.max = 70 // maxPoint
  optionsBrush.yaxis.max = 70 // maxPoint

  const maxStd = 35
  const line = maxPoint >= maxStd ? { color: '#b22200', dash: 3 } : { color: '#3ca53a', dash: 0 }
  optionsMain.annotations.yaxis = [
    {
      y: maxStd,
      strokeDashArray: line.dash,
      borderColor: line.color,
      label: {
        borderColor: line.color,
        style: {
          color: '#fff',
          background: line.color
        },
        text: `${maxStd} Pa`
      }
    }
  ]

  return (
    <div>
      <div id="charts">
        <div id="series">
          <Chart options={optionsMain} series={[{ name: 'Air Pressure', data: datapoints }]} height="360" />
        </div>
        <div id="brush">
          <Chart options={optionsBrush} series={[{ name: 'AP', data: datapoints }]} height="130" />
        </div>
      </div>
    </div>
  )
}

export default AirpressureTimeseries
