import { unpack } from 'fns/base64/urlsafe-base64'
import moment from 'moment'

export const statement = timeRange => {
  const { start, end } = timeRange
  return {
    id: '99',
    query: 'select r.device.channels from r where(r.device.metadata.msg_dt between @start  and @end ) ORDER BY r.device.metadata.msg_dt desc',
    parameters: [{ name: '@start', value: start }, { name: '@end', value: end }]
  }
}

const formatter = (docs, asyncReturn) => {
  const first = docs && docs[0]
  const data = docs.reduce(
    (acc, d, i) => {
      const [x, y, z] = d.channels
      const period = parseInt(x.p_stats, 10)
      const timestamp = x.acq_dt

      const asData = (v, i) => {
        const time = moment(timestamp)
          .subtract(period * i, 'seconds')
          .toISOString()
        return { v, time, timestamp, i }
      }

      acc.x = acc.x.concat(Array.from(unpack(x.peak_data)).map(asData))
      acc.y = acc.y.concat(Array.from(unpack(y.peak_data)).map(asData))
      acc.z = acc.z.concat(Array.from(unpack(z.peak_data)).map(asData))
      return acc
    },
    { x: [], y: [], z: [] }
  )
  asyncReturn({ data, docs })
}

export const vibRetrofitted = { statement, formatter }
