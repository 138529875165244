import { connect } from 'react-redux'

const connector = connect(
  state => {
    const { login, permissions, role, screeName, branding } = state
    const { user, authError } = login
    return { user, authError, permissions, role, screeName, branding }
  },
  dispatch => {
    const whenAuthenticateAttempt = ({ user, pass }) => {
      dispatch({ type: 'LOGIN_REQUEST', payload: { user, pass } })
    }
    return { whenAuthenticateAttempt }
  }
)

export default connector
