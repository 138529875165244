import { reindex, getItemFromSlug, listItemsFromIds, indexTable } from 'domain-mmd/io-mmd/backoffice/utils'
import { tables } from 'domain-mmd/io-mmd/backoffice/tables'

export const backofficeAuthenticate = (mgr, user, pass, overwrite) => {
  let diff = {}
  if (overwrite === 'admin' && user === 'ALL') {
    const accounts = mgr.getTable(tables.ACCOUNTS).items
    diff = {
      branding: 'micromega',
      permissions: 'affiliate',
      screenName: 'Micromega',
      role: 'mmd_root',
      accounts
    }
  } else {
    let hasUser = true
    if (overwrite === 'admin' && !user) {
      user = 'modyva'
    } else if (user === 'Modyva' && pass === 'mod091118*') {
      user = 'modyva'
    } else if (user === 'Lhoist' && pass === 'mod091118*') {
      user = 'lhoist'
    } else if (user === 'Pommeroeul' && pass === 'Vobox*60') {
      user = 'pommeroeul'
    } else {
      hasUser = false
    }

    const { slug: userSlug, screen_name, role: roleIds } = getItemFromSlug(mgr.getTable(tables.USERS))(user) || {}
    const { accounts: accountIds, affiliation: affiliationIds, permissions } = (listItemsFromIds(mgr.getTable(tables.ROLES), roleIds) || []).shift() || {}
    const { slug: affiliation } = (listItemsFromIds(mgr.getTable(tables.AFFILIATIONS), affiliationIds) || [])[0] || {}
    const accounts = accountIds ? mgr.expand(tables.ACCOUNTS)(accountIds) : []
    diff = {
      branding: affiliation,
      screenName: screen_name,
      permissions,
      accounts
    }
  }

  return {
    branding: 'micromega',
    screenName: 'Visitor',
    permissions: [],
    accounts: [],
    ...diff
  }
}

const defaultLayouts = `[{"stream":"lho-2-air","dashboard":"AirPressureTimeseries"},{"stream":"lho-1-vib","dashboard":"VibrationTimeSeries"}]`

class BackofficeManager {
  constructor() {
    this.state = {}
  }

  /*
affiliations: indexItems(affiliations),
roles: indexItems(roles),
accounts: indexItems(accounts),
data_streams: indexItems(data_streams),
setups: indexItems(setups),
users: indexItems(users),
icons: indexItems(icons)

*/
  authenticate(user, pass, overwrite) {
    return backofficeAuthenticate(this, user, pass, overwrite)
  }
  listItemsFromIds(tableName, ids) {
    return listItemsFromIds(this.state[tableName], ids)
  }

  getTable(tableName) {
    return indexTable(this.state[tableName]) || {}
  }

  getActiveLens(lens) {
    const lensItem = getItemFromSlug(this.getTable(tables.LENSES))(lens) || {}
    const { slug, i18n, account: accountIds, data_streams: streamIds, environnements, layouts = defaultLayouts } = lensItem
    const account = (listItemsFromIds(this.getTable(tables.ACCOUNTS), accountIds) || []).shift()
    const streams = listItemsFromIds(this.getTable(tables.DATA_STREAMS), streamIds)
    return { slug, i18n, account, streams, dashboardLayout: JSON.parse(layouts) }
  }

  expand(tableName) {
    const { ids, items } = indexTable(this.state[tableName]) || {}
    const itemMapper = d => {
      if (!ids) {
        return
      }
      const idx = ids[d]
      return items[idx]
    }
    return arr => {
      return (arr || []).map(itemMapper)
    }
  }
  /*
  {(sources || []).map(backofficeMgr.expand('data_stream')).sort(i18nSort)}
  */
  setState(obj) {
    Object.entries(obj).forEach(([k, v]) => {
      this.state[k] = v
    })
  }
}

export default BackofficeManager
