export const lenses = [
  {
    account: ['rec1KXFJWYqisujda'],
    status: 'active',
    lens_uid: '1-blasting-air',
    i18n: 'Air pressure - Chateau de Melroy - Vezin',
    environnements: ['recovibio', 'staging', 'dev'],
    data_streams: ['recLxhIA6Bnvt1xcG'],
    slug: 'lhoist-quarry-marchelesdames__1-blasting-air',
    _id: 'recbhTePz1FBmCE3M'
  },
  {
    account: ['rec1KXFJWYqisujda'],
    status: 'active',
    lens_uid: '2-blasting-vib',
    i18n: 'Building vibration - 81 rue Emile Vandevelde - Namêche',
    environnements: ['pr202'],
    data_streams: ['reca6za0b0tgTht9L'],
    slug: 'lhoist-quarry-marchelesdames__2-blasting-vib',
    _id: 'recIOJpvN1aODyJYL'
  },
  {
    account: ['recga2zwUMbFlh4yv'],
    status: 'inactive',
    lens_uid: '1-vib',
    i18n: 'Sensitive Equipment - Vibration Monitoring',
    data_streams: ['recvJ8iTYK3juJ5h8'],
    slug: 'bierset-ground-radar__1-vib',
    _id: 'recqHY8CxPqYfppo7'
  },
  {
    account: ['reckaKC5QcyUbTXQa'],
    status: 'inactive',
    lens_uid: '1-vib-resident',
    i18n: 'Building vibration ',
    data_streams: ['recSBNeipTz37MEqd', 'rec8P9nhHyXsIUvNX'],
    slug: 'thomas-piron-mons__1-vib-resident',
    _id: 'recuHGbbtfNd51iGM'
  },
  {
    account: ['reciy0qk1uAodJbNq'],
    status: 'inactive',
    lens_uid: '1-dust+vib',
    i18n: 'Building vibration & dust monitoring',
    data_streams: ['recoPHTnfctQNZZj9', 'recVrFS2OsKkRqW3O'],
    slug: 'ec-museum__1-dust+vib',
    _id: 'recs5WZqExPH7RwD2'
  },
  {
    account: ['recTOr2J3qqBwmDGS'],
    status: 'inactive',
    lens_uid: '1-vib',
    i18n: 'Building vibration',
    data_streams: ['rec91LQaRUP2YO0zn'],
    slug: 'edith-cavel-hospital__1-vib',
    _id: 'rec3lnBPGtFUquYwu'
  },
  {
    account: ['reclxrL0Cup5N6Pr0'],
    status: 'inactive',
    lens_uid: '1-tmd',
    i18n: 'Tuned mass damper efficiency',
    data_streams: ['recxPkJjvFX9ep40E', 'recWYMGM5POyNd7Wp', 'recC1RY9E9pMDfbzO'],
    slug: 'walibi-nl-roller-coaster__1-tmd',
    _id: 'recv4nk6fxEoHeahC'
  },
  {
    account: ['recwnCRazGSgCOjxj'],
    status: 'inactive',
    lens_uid: '1-tmd',
    i18n: 'Tuned mass damper efficiency',
    data_streams: ['recWq8AdtvfzgbBdh', 'recsb04BUGn5GdJZE', 'recdE45STLnoRQXoP'],
    slug: 'flagpole__1-tmd',
    _id: 'recGUyqgcJ7zwWEnV'
  },
  {
    account: ['recLyFFSsyqhuR7LE'],
    status: 'inactive',
    lens_uid: '1-mon',
    i18n: 'Rails elongations',
    data_streams: ['reczQrLzwcnhs9ai2', 'reciy3WiVuSK6TURe', 'rec4aijszCw0NNeaj', 'recMAYnjeShJ2TU6y', 'recvzchb0VfGSJgjA', 'recGgDJqZ5HE0Vvcw'],
    slug: 'melb-heaven-rails__1-mon',
    _id: 'recV5BeY5BFAoZsBg'
  },
  {
    account: ['recCKqe7MXx7JVBv5'],
    status: 'inactive',
    lens_uid: '1-mon',
    i18n: 'Rails condition monitoring',
    data_streams: ['recquF6DJuIrwwJdC'],
    slug: 'ny-heaven-rails__1-mon',
    _id: 'recMhmNdp0MqD3WlH'
  },
  {
    account: ['recwg1YVktabydyS6'],
    status: 'inactive',
    lens_uid: '1-str',
    i18n: 'Pylons structural monitoring',
    data_streams: ['recOrTN86YJochoOW'],
    slug: 'gsm-pylon-structural-integrity__1-str',
    _id: 'recGNXx1XwpuslTII'
  },
  {
    account: ['recJobaZWqkus6d5p'],
    status: 'inactive',
    lens_uid: '1-str',
    i18n: 'Wind turbines integrity monitoring',
    data_streams: ['recEwPg9XshyyWtu7'],
    slug: 'wind-turbine__1-str',
    _id: 'recTV7J5ztzNmeyV1'
  },
  {
    account: ['rectiQruVVAX6TTjz'],
    status: 'inactive',
    lens_uid: '1-mon',
    i18n: 'Cooling system condition monitoring',
    data_streams: ['recyMINUTVLCzz2qL', 'recTJvvnO1tAK4kpS', 'recBi8lw71cH5EME9', 'recPPHY6AWuFGyh1j'],
    slug: 'cooling-system__1-mon',
    _id: 'recDPM0AyYPg01e9b'
  },
  {
    account: ['recmCJ0zwOsnoNNzv'],
    status: 'inactive',
    lens_uid: '1-str',
    i18n: 'Structural Monitoring',
    data_streams: [
      'recVgyOlCT82uu00F',
      'reccSXMPSO5FNs5QQ',
      'recjsrR35WaSNtMvK',
      'reckEBCIbOJBz1hAV',
      'recJ06o66XKyj2lhz',
      'reczu9EMYTMbotMVP',
      'rechGRZ9PkhxxtL8P'
    ],
    slug: 'mumbai-pagoda__1-str',
    _id: 'recw9FzF9RHGiV8p7'
  },
  {
    account: ['rec5xAq6CClE4T2oE'],
    status: 'active',
    lens_uid: '1-vib',
    i18n: 'Building Vibration',
    data_streams: ['recCaPYRzVpL22b5U'],
    slug: 'pommeroeul-railways__1-vib',
    _id: 'recf4wZcfFAXY1neg'
  }
]
