import React, { Component } from 'react'
import SplitNav from 'design-system/split-nav'

import BrandLogo from 'design-system-affiliate/masthead-logo'
import RecovibLogo from 'design-system-mmd/recovib-logo'

const right = (
  <div>
    <RecovibLogo height={48} />
  </div>
)

class Masthead extends Component {
  render() {
    const { branding, padding } = { padding: 0, ...this.props }
    const logo = <BrandLogo height={38} branding={branding || 'micromega'} />
    return (
      <div style={{ padding }}>
        <SplitNav main={logo} right={right} />
      </div>
    )
  }
}

// <SplitNav />

Masthead.propTypes = {}

export default Masthead
