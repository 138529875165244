import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { tables } from 'domain-mmd/io-mmd/backoffice/tables'
import Button from 'design-system/button'

const Summary = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 8px;
  width: 100%;
`
const HBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  margin-bottom: 2px;
  align-items: center;
  height: 24px;
  max-height: 24px;
`

const Signals = styled.div``

const Icon = styled.img`
  max-width: 24px;
  max-height: 24px;
  padding-right: 4px;
  opacity: ${props => (props.disabled ? 0.1 : 1)};
`

const More = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  max-width: 32px;
`

const Sep = styled.div`
  display: inline-block;
  border-right: 1px dashed #afafaf;
  margin-right: 8px;
  padding-right: 4px;
  height: 100%;
`

const Outputs = styled.div``

const TimeStamp = styled.div`
  font-family: 'Avenir Next Condensed';
`
const StatusTechnique = styled.div``
const Actions = styled.div`
  text-align: right;
  flex: 1;
`

class AccountLensSummary extends Component {
  constructor(props) {
    super(props)
    this.bound = {
      onLensSelected: event => {
        const { slug } = event.target.dataset
        const cb = this.props.whenLens
        if (typeof cb === 'function') {
          cb(slug)
        }
      }
    }
  }
  render() {
    const { item, backofficeMgr } = this.props
    const { onLensSelected } = this.bound
    const dataStreams = backofficeMgr.expand(tables.DATA_STREAMS)(item.data_streams)
    const setups = dataStreams.reduce((acc, d) => {
      const items = backofficeMgr.expand(tables.SETUPS)(d.setups)
      items.forEach(d => {})
      if (items) {
        items.forEach(d => {
          acc.push(d.slug)
          ;(d.tags || []).forEach(tags => {
            acc = acc.concat(tags)
          })
        })
      }
      return acc
    }, [])
    const triggers = setups
      .reduce((acc, d) => {
        const m = (d.match(/trigger:(air-pressure|vibration|dust|voltage|sns|temperature)/) || [null, null])[1]
        if (m && !acc.includes(m)) {
          acc.push(m)
        }
        return acc
      }, [])
      .sort()
    const ix = setups.reduce((acc, d) => {
      const m = (d.match(/ix:.*$/) || [null, null])[1]
      if (m && !acc.includes(m)) {
        acc.push(m)
      }
      return acc
    }, [])
    const types = setups.reduce((acc, d) => {
      const m = (d.match(/type:(burst-data)/) || [null, null])[1]
      if (m && !acc.includes(m)) {
        acc.push(m)
      }
      return acc
    }, [])
    const status = item.layouts ? 'status-green' : 'status-red' //  Math.round(Math.random() * 10) % 6 === 1 ? 'status-red' : 'status-green'
    const bar = item.layouts ? 'bar-4' : 'bar-0'
    const timestamp = new Date()
    const actionFragment =
      item.status === 'active' ? (
        <Button primary onClick={onLensSelected} data-slug={item.slug}>
          Monitor
        </Button>
      ) : (
        <Button disabled data-slug={item.slug}>
          Inactive
        </Button>
      )
    return (
      <Summary>
        <HBox>
          <Outputs>
            <Icon src="https://assets.recovib.io/icons/sms.png" disabled={ix.includes('sms')} />
            <Icon src="https://assets.recovib.io/icons/email.png" disabled={ix.includes('email')} />
          </Outputs>
          <Sep />
          <Signals>
            {triggers.map(d => {
              return <Icon key={d} src={`https://assets.recovib.io/icons/${d}.png`} />
            })}
          </Signals>
          <Sep />
          <Outputs>
            <Icon src="https://assets.recovib.io/icons/burst-data.png" disabled={types.includes('burst-data')} />
          </Outputs>
          <Actions>{actionFragment}</Actions>
        </HBox>
      </Summary>
    )
  }
}

export default AccountLensSummary

/*

return (
  <Summary>
    <HBox>
      <StatusTechnique>
        <Icon src={`https://assets.recovib.io/icons/${status}.png`} />
      </StatusTechnique>
      <Sep />
      <Outputs>
        <Icon src="https://assets.recovib.io/icons/sms.png" disabled={ix.includes('sms')} />
        <Icon src="https://assets.recovib.io/icons/email.png" disabled={ix.includes('email')} />
      </Outputs>
      <Sep />
      <Actions>{actionFragment}</Actions>
    </HBox>

    <HBox>
      <Signals>
        {triggers.map(d => {
          return <Icon key={d} src={`https://assets.recovib.io/icons/${d}.png`} />
        })}
      </Signals>
      <Sep />
      <Outputs>
        <Icon src="https://assets.recovib.io/icons/burst-data.png" disabled={types.includes('burst-data')} />
      </Outputs>
    </HBox>
    <HBox>
      <TimeStamp>{timestamp.toISOString().split('T')[1]}</TimeStamp>
      <Sep />
      <StatusTechnique>
        <Icon src={`https://assets.recovib.io/icons/${bar}.png`} />
      </StatusTechnique>
    </HBox>
  </Summary>
)
*/
