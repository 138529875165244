export const users = [
  {
    slug: 'mmd_root',
    screen_name: 'mmd_root',
    role: ['rec4x5m6sX9WitcjV', 'recAf0M5Ai2UaIRYy'],
    _id: 'recSX7j7LbnYqWKHU'
  },
  {
    slug: 'demo',
    screen_name: 'demo',
    date_joined: '2019-02-18 14:44:28.399046;',
    password: 'pbkdf2_sha256$100000$ztNqunkTzAmG$49sb7yIsR4aAvw+OAhs2bLeLlI6zoX6cMWDG3VLmj00=',
    last_login: '2019-08-08 07:24:58.340260',
    _id: 'recIl2UkGGBrPfx5l'
  },
  {
    email: 's.thijs@thomas-piron.eu',
    slug: 'stephane_thijs',
    screen_name: 'Stephane Thijs',
    date_joined: '2019-05-27 07:26:49.000000',
    password: 'pbkdf2_sha256$100000$MKysEp1bTOly\\$3n8cBdoKy0M1PoAr5b7z/1PqtYF2tsAnCK55DxXIoSc=',
    last_login: '2019-05-27 07:33:37.368626',
    _id: 'recv0es50kxLHYeME'
  },
  {
    slug: 'modyva',
    screen_name: 'Modyva',
    date_joined: '2018-11-09 08:09:04.033328',
    password: 'pbkdf2_sha256$100000$i5lL2TFKOMxA$3zJ2WpinqlT2GICq33pdGqAH7TKc7bfgH8DR5OwMdeI=',
    last_login: '2019-08-13 09:02:09.341681',
    role: ['recWhESvBQhWAoKWJ'],
    _id: 'recrxBMZfhnedNQrg'
  },
  {
    slug: 'lhoist',
    screen_name: 'Lhoist',
    role: ['recv4PY3DyhlXjs2O'],
    _id: 'recWLtKvvfz71g16o'
  },
  {
    slug: 'gantrex',
    screen_name: 'Gantrex',
    role: ['rec5P6GryMhqOLxuv'],
    _id: 'reciIkEaPYyPvLw5R'
  },
  {
    slug: 'flow_engineering',
    screen_name: 'Flow Engineering',
    role: ['recAXFZv1FVRR7zjf'],
    _id: 'recFdhdrZldFt0hLw'
  },
  {
    email: 'cpromper@chpconsult.be',
    slug: 'christophe_promper',
    screen_name: 'Christophe Promper',
    date_joined: '2018-12-21 10:40:35.000000',
    password: 'pbkdf2_sha256$100000$HGSTzDAQidQU$rRH7G1LlEmELAZLmIEYFHuIOANA+Uj/ikibotGr7UW8=',
    last_login: '2019-01-03 14:14:07.656704',
    _id: 'recwQNPQEgtVtYEXB'
  },
  {
    email: 'jon.vansant@vasantengineering.com',
    slug: 'Jon Vansant',
    screen_name: 'Jon Vansant',
    date_joined: '2019-01-24 10:24:42.000000',
    password: 'pbkdf2_sha256$100000$XRt1FKFUlaCZ$LYh968+C2Oc33O/QryC7Gbw2YG96ewV3H/DXjhhek10=',
    last_login: '2019-04-30 08:53:12.028521',
    _id: 'reckmafKHDR6FOOrd'
  },
  {
    email: 'j.baudoux@thomas-piron.eu',
    slug: 'Justine Baudoux',
    screen_name: 'Justine Baudoux',
    date_joined: '2019-05-27 07:23:03.000000',
    password: 'pbkdf2_sha256$100000$5yv2S4fWRHg4$+/vkfJglFG1zDmWlzdGT5KE1YrPOq4Nom4mp3CcJWNM=',
    last_login: 'NULL',
    _id: 'recsql5kuEd8JG4fg'
  },
  {
    slug: 'pommeroeul',
    screen_name: 'Ville Pommeroeul',
    role: ['recAf0M5Ai2UaIRYy'],
    _id: 'rec7LB6qqFX4BUwEf'
  }
]
