export const accounts = [
  {
    country_i18n: 'Belgium',
    slug: 'lhoist-quarry-marchelesdames',
    'account-manager': ['recvWEyI3yT9BW4Uj'],
    lenses: ['recbhTePz1FBmCE3M', 'recIOJpvN1aODyJYL'],
    location_i18n: 'Marche-les-Dames',
    status: 'active',
    i18n: 'Lhoist Quarry',
    roles: ['rec86csWr6koH1yzo', 'recv4PY3DyhlXjs2O', 'recWhESvBQhWAoKWJ'],
    _id: 'rec1KXFJWYqisujda'
  },
  {
    country_i18n: 'Belgium',
    slug: 'bierset-ground-radar',
    'account-manager': ['recvWEyI3yT9BW4Uj'],
    lenses: ['recqHY8CxPqYfppo7'],
    location_i18n: 'Bierset - Galère Construction Work',
    status: 'closed',
    i18n: 'Airport Ground Radar',
    roles: ['recTuvPlNvLR47YAY', 'recWhESvBQhWAoKWJ'],
    _id: 'recga2zwUMbFlh4yv'
  },
  {
    country_i18n: 'Belgium',
    slug: 'thomas-piron-mons',
    'account-manager': ['recvWEyI3yT9BW4Uj'],
    lenses: ['recuHGbbtfNd51iGM'],
    location_i18n: 'Mons',
    status: 'active',
    i18n: 'Thomas-Piron Construction Work',
    roles: ['rec9PlnfOyxJLesXG', 'recbUdI4S2SQO6fSG', 'recWhESvBQhWAoKWJ'],
    _id: 'reckaKC5QcyUbTXQa'
  },
  {
    country_i18n: 'Belgium',
    slug: 'ec-museum',
    'account-manager': ['recvWEyI3yT9BW4Uj'],
    lenses: ['recs5WZqExPH7RwD2'],
    location_i18n: 'Brussels',
    status: 'closed',
    i18n: 'EC Museum',
    roles: ['rec3zjBSl3supVrdH', 'recWhESvBQhWAoKWJ'],
    _id: 'reciy0qk1uAodJbNq'
  },
  {
    country_i18n: 'Belgium',
    slug: 'edith-cavel-hospital',
    'account-manager': ['recvWEyI3yT9BW4Uj'],
    lenses: ['rec3lnBPGtFUquYwu'],
    location_i18n: 'Brussels',
    status: 'canceled',
    i18n: 'Edith Cavel Hospital',
    roles: ['recrQQPCOkPQ1LN6z', 'recWhESvBQhWAoKWJ'],
    _id: 'recTOr2J3qqBwmDGS'
  },
  {
    country_i18n: 'Netherlands',
    slug: 'walibi-nl-roller-coaster',
    'account-manager': ['rec7IkhJXt0UCI4gy'],
    lenses: ['recv4nk6fxEoHeahC'],
    location_i18n: 'Biddinghuizen',
    status: 're-start-pending ',
    i18n: 'Walibi Holland Roller Coaster',
    roles: ['recOOm0n4gxUVws2d', 'recAXFZv1FVRR7zjf'],
    _id: 'reclxrL0Cup5N6Pr0'
  },
  {
    country_i18n: 'USA',
    slug: 'flagpole',
    'account-manager': ['rec7IkhJXt0UCI4gy'],
    lenses: ['recGUyqgcJ7zwWEnV'],
    location_i18n: 'Sheboygan Winsconsin',
    status: 'proposed',
    i18n: 'Flagpole',
    roles: ['recuH54acD7GGz1VT', 'recAXFZv1FVRR7zjf'],
    _id: 'recwnCRazGSgCOjxj'
  },
  {
    country_i18n: 'Australia',
    slug: 'melb-heaven-rails',
    'account-manager': ['recmwl0uYaUKl61Rj'],
    lenses: ['recV5BeY5BFAoZsBg'],
    location_i18n: 'Melbourne',
    status: 'quoted',
    i18n: 'Crane floor rails - Melbourne Heaven',
    roles: ['recj7ejjLSYcUwDwS', 'rec5P6GryMhqOLxuv'],
    _id: 'recLyFFSsyqhuR7LE'
  },
  {
    country_i18n: 'USA',
    slug: 'ny-heaven-rails',
    'account-manager': ['recXTMFAyaZHCCqHD'],
    lenses: ['recMhmNdp0MqD3WlH'],
    location_i18n: 'New York',
    status: 'proposed',
    i18n: 'Crane rails - New York Heaven',
    roles: ['recZ1rJcUAHhvm8oQ', 'rec4x5m6sX9WitcjV'],
    _id: 'recCKqe7MXx7JVBv5'
  },
  {
    country_i18n: 'Canada',
    slug: 'gsm-pylon-structural-integrity',
    'account-manager': ['recXTMFAyaZHCCqHD'],
    lenses: ['recGNXx1XwpuslTII'],
    location_i18n: 'Canada',
    status: 'proposed',
    i18n: 'GSM pylons',
    roles: ['rec4x5m6sX9WitcjV'],
    _id: 'recwg1YVktabydyS6'
  },
  {
    country_i18n: 'South Africa',
    slug: 'wind-turbine',
    'account-manager': ['recXTMFAyaZHCCqHD'],
    lenses: ['recTV7J5ztzNmeyV1'],
    location_i18n: 'South Africa',
    status: 'proposed',
    i18n: '30 MW Wind turbines',
    roles: ['rec4x5m6sX9WitcjV'],
    _id: 'recJobaZWqkus6d5p'
  },
  {
    country_i18n: 'South Korea',
    slug: 'cooling-system',
    'account-manager': ['recXTMFAyaZHCCqHD'],
    lenses: ['recDPM0AyYPg01e9b'],
    status: 'proposed',
    i18n: 'Cooling System Monitoring',
    roles: ['rec4x5m6sX9WitcjV'],
    _id: 'rectiQruVVAX6TTjz'
  },
  {
    country_i18n: 'India',
    slug: 'mumbai-pagoda',
    'account-manager': ['recXTMFAyaZHCCqHD'],
    lenses: ['recw9FzF9RHGiV8p7'],
    location_i18n: 'Mumbai',
    status: 'proposed',
    i18n: 'Global Vipassana Pagoda',
    roles: ['rec4x5m6sX9WitcjV'],
    _id: 'recmCJ0zwOsnoNNzv'
  },
  {
    country_i18n: 'Belgium',
    slug: 'pommeroeul-railways',
    'account-manager': ['recXTMFAyaZHCCqHD'],
    lenses: ['recf4wZcfFAXY1neg'],
    location_i18n: 'Ville Pommeroeul',
    status: 'proposed',
    i18n: 'Railways Vibration',
    roles: ['recAf0M5Ai2UaIRYy', 'rec4x5m6sX9WitcjV'],
    _id: 'rec5xAq6CClE4T2oE'
  }
]
