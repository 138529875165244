import roleRules from './rbac-rules'
import canPerformWithRules from 'domain/flow/rbac/canPerformWithRules'
import { createStore } from 'redux'
// tables
import { accounts as accountsAll } from 'domain-mmd/backoffice-data/accounts'
import { lenses } from 'domain-mmd/backoffice-data/lenses'
import { data_streams } from 'domain-mmd/backoffice-data/data_streams'
import { icons } from 'domain-mmd/backoffice-data/icons'
import { roles } from 'domain-mmd/backoffice-data/roles'
import { affiliations } from 'domain-mmd/backoffice-data/affiliations'
import { users } from 'domain-mmd/backoffice-data/users'
import { setups } from 'domain-mmd/backoffice-data/setups'
// utils
import { indexItems } from 'domain-mmd/io-mmd/backoffice/utils'
import { tables } from 'domain-mmd/io-mmd/backoffice/tables'
import BackofficeManager from 'domain-mmd/io-mmd/backoffice/BackofficeManager'

const backofficeMgr = new BackofficeManager()
backofficeMgr.setState({
  [tables.ACCOUNTS]: accountsAll,
  [tables.LENSES]: lenses,
  [tables.ROLES]: roles,
  [tables.DATA_STREAMS]: data_streams,
  [tables.SETUPS]: setups,
  [tables.USERS]: users,
  [tables.AFFILIATIONS]: affiliations,
  [tables.ICONS]: icons
})

const queryString = new URLSearchParams(window.location.search)
const prodStore = {
  backend_down: false,
  branding: 'modyva',
  route_active: '/first',
  login: { authError: 'Please Login', user: undefined },
  permissions: {},
  activeLens: undefined,
  backofficeMgr
}
const devStore = {
  backend_down: false,
  branding: 'modyva',
  route_active: '/first',
  login: { authError: undefined, user: 'Modyva' },
  permissions: {},
  activeLens: undefined,
  backofficeMgr
}

const defaultStore = prodStore

const rootReducer = (state = defaultStore, action) => {
  let diff
  const { type, payload } = action
  switch (type) {
    case 'LOGIN_REQUEST':
      {
        const { backofficeMgr } = state
        const { user, pass, overwrite } = payload
        const { permissions, accounts, screenName, role, branding } = backofficeMgr.authenticate(user, pass, overwrite)
        if (permissions) {
          diff = { login: { user, authError: undefined }, permissions, accounts }
        } else {
          diff = {
            login: { user: undefined, authError: 'Your credentials are incorrect. Please Try Again' },
            permissions: undefined,
            accounts: []
          }
        }
        diff = { ...diff, branding, screenName }
      }
      break
    case 'EXIT_LENS_DASHBOARD':
      diff = { activeLens: undefined }
      break
    case 'TIME_RANGE_CHANGE':
      diff = { timeRange: payload }
      break
    case 'STREAM_DATA_CHANGE':
      diff = { streamLastUpdate: payload }
      break
    case 'LENS_ACTIVE_CHANGE': {
      const { backofficeMgr } = state
      const { slug } = payload
      diff = { activeLens: backofficeMgr.getActiveLens(slug) }
      // diff = { airpressure: { data: [], loading: false, error: payload } }
      break
    }

    default:
      diff = undefined
      break
  }
  // this needs to be a *different* object to trigger refreshes
  return diff ? { ...state, ...diff } : state
}

const initializeRoles = store => {
  // Fetch any data that the application depends on.
  const roleKV = d => {
    return [d.slug, d.i18n]
  }
}

export const initializeDomain = () => {
  // console.log('PROCESS', process.browser, process.env.NODE_ENV)

  const store = createStore(rootReducer)
  if (queryString.get('role') === 'admin') {
    const user = queryString.get('user')
    store.dispatch({ type: 'LOGIN_REQUEST', payload: { user, overwrite: 'admin' } })
  }
  return { store, flow: {} }

  const canPerform = canPerformWithRules(roleRules)
  initializeRoles(store)
  return { store, flow: { canPerform } }
}
