import styled from 'styled-components'

const Thirds = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

Thirds.i = styled.div`
  flex-grow: 1;
  width: 32%;
  text-align: center;
`

export default Thirds
