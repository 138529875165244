import React, { Component } from 'react'
import Form from 'design-system/form'
import { View, VSpacer, Padding } from 'design-system/'

class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = { authError: undefined, user: undefined, pass: undefined }
    this.bound = {
      onAuthenticate: event => {
        const { whenAuthenticateAttempt } = this.props
        const { user, pass } = this.state
        if (typeof whenAuthenticateAttempt === 'function') {
          whenAuthenticateAttempt({ user, pass })
        }
      },
      whenUserTyped: user => {
        this.setState({ user })
      },
      whenPassTyped: pass => {
        this.setState({ pass })
      }
    }
  }

  render() {
    const { authError } = this.props
    const { onAuthenticate, whenUserTyped, whenPassTyped } = this.bound
    const { FormTitle, FormSubheading } = this.props.styled || { FormTitle: View, FormSubheading: View }
    return (
      <mg-manager-login>
        <Padding all={32}>
          <View>
            <FormTitle>Authentification Required</FormTitle>
            <FormSubheading>Please log in, to access the system</FormSubheading>
          </View>
          <VSpacer height={16} />
          <Form.Wrapper
            novalidate="novalidate"
            onSubmit={event => {
              event.preventDefault()
            }}
          >
            <Form.FieldSet>
              <Form.Section {...{ label: 'Username', icon: 'user', name: 'username', prompt: 'Please enter username' }} whenChange={whenUserTyped} />
              <Form.Section {...{ label: 'Password', icon: 'lock', name: 'password', prompt: 'Enter your password' }} whenChange={whenPassTyped} />
            </Form.FieldSet>
            <Form.Actions>
              <Form.Button primary rightAlign onClick={onAuthenticate}>
                sign-in
              </Form.Button>
            </Form.Actions>
          </Form.Wrapper>
          {authError && <View>{authError}</View>}
        </Padding>
      </mg-manager-login>
    )
  }
}

/*
NOTE: Adding a novalidate attribute to the form element prevents native validation on form elements (if applied), allowing your JavaScript unobstructed ability to manage all validation
*/

export default LoginForm
