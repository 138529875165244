import React, { Component, Fragment } from 'react'
// Base
import { View, VSpacer } from 'design-system'
// Blocks
import Button from 'design-system/button'
import WidgetProblems from 'blocks-mmd/widget/WidgetProblems'

class Widget extends Component {
  render() {
    const { data, Factory } = this.props
    const { loading, error, nodata } = data || {}

    console.log('Widget', data)
    let fragment = null

    if (loading || error || nodata) {
      fragment = <WidgetProblems {...{ loading, error, nodata }} />
    } else if (!Factory) {
      fragment = <View>No Widget specified</View>
    } else {
      fragment = <Factory {...data} />
    }

    return <Fragment>{fragment}</Fragment>
  }
}

export default Widget
