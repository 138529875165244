import React, { Component } from 'react'
import { connect } from 'react-redux'
// primitives
import { View } from 'design-system'
// blocks
import SitePage from 'design-system-mmd/recovibcloud-site/'
import AccountsExplorer from 'blocks-mmd/accounts-explorer'
import LensDashboard from 'blocks-mmd/lens-dashboard'
// flow
import { initializeDomain } from './initializeDomain'
import connectSite from 'domain-mmd/redux-mmd/site/connect'
import connectAccounts from 'domain-mmd/redux-mmd/accounts/connect'
import connectLens from 'domain-mmd/redux-mmd/lens/connect'

// --- main -----------------------------

const { flow, store } = initializeDomain()

const ConnectedSite = connectSite(SitePage)
const ConnectedAccounts = connectAccounts(AccountsExplorer)
const ConnectedLens = connectLens(LensDashboard)

class App extends Component {
  constructor(props) {
    super(props)
    this.state = { activeRoute: undefined }
  }
  render() {
    store.subscribe(d => {
      const { activeLens } = store.getState()
      if ((this.state.activeLens || {}).slug !== (activeLens || {}).slug) {
        this.setState({ activeLens })
      }
    })
    const { activeLens } = this.state
    const activeRoute = activeLens ? <ConnectedLens store={store} /> : <ConnectedAccounts store={store} />
    return <ConnectedSite store={store} activeRoute={activeRoute} />
  }
}

export default App
