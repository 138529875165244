export const tables = {
  AFFILIATIONS: 'affiliations',
  ROLES: 'roles',
  ACCOUNTS: 'accounts',
  DATA_STREAMS: 'data_streams',
  USER_ENDPOINTS: 'user_endpoints',
  MESSAGE_PACKETS: 'message_packets',
  SETUPS: 'setups',
  LENSES: 'lenses',
  USERS: 'users',
  DEVICES: 'devices',
  PULUMI_STACKS: 'pulumi_stacks',
  TLM_MESSAGE_TYPES: 'tlm_message_types',
  ICONS: 'icons'
}
