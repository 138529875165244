import moment from 'moment'

const statement = (timeRange, name = 'Railways') => {
  const { start, end } = timeRange
  return {
    id: '99',
    query:
      'select r.acq_dt, r.peak_data as peak, r.n_stats, r.p_stats, r.virtual_channel_units, r.name  from r where (r.msg_dt between @start  and @end ) AND r.tlm_type = @tlm AND r.name = @name ORDER BY r.msg_dt desc',
    parameters: [{ name: '@start', value: start }, { name: '@end', value: end }, { name: '@tlm', value: 4 }, { name: '@name', value: name }]
  }
}

const dump = timeRange => {
  const { start, end } = timeRange
  return {
    id: '99',
    query: 'select * from r where (r.msg_dt between @start  and @end ) AND r.tlm_type = @tlm AND r.name = @name ORDER BY r.msg_dt desc',
    parameters: [{ name: '@start', value: start }, { name: '@end', value: end }, { name: '@tlm', value: 4 }, { name: '@name', value: 'TBD' }]
  }
}

const formatter = (docs, asyncReturn) => {
  const data = docs.reduce(
    (acc, d, i) => {
      const period = parseInt(d.p_stats, 10)
      const n = parseInt(d.n_stats, 10)
      const peak = d.peak.split('|')
      const x = peak.splice(0, n)
      const y = peak.splice(0, n)
      const z = peak.splice(0, n)

      const timestamp = d.acq_dt

      const asData = (v, i) => {
        const time = moment(timestamp)
          .subtract(period * i, 'seconds')
          .toISOString()
        return { v, time, timestamp, i }
      }

      acc.x = acc.x.concat(x.map(asData))
      acc.y = acc.y.concat(y.map(asData))
      acc.z = acc.z.concat(z.map(asData))
      return acc
    },
    { x: [], y: [], z: [] }
  )
  asyncReturn({ data, docs })
}

export const vibDefault = { statement, formatter }
export const vibDump = { dump, formatter }
