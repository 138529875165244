// base
import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Padding, VSpacer } from 'design-system'

const NoticeOverlay = styled.div``

const Site = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Page = styled.div`
  display: flex;
  flex: 1;
  margin: 0px 8px;
`

const SinglePageApp = props => {
  const { helmet, page, masthead, cookienotice, footer } = props
  return (
    <Fragment>
      <NoticeOverlay>{cookienotice}</NoticeOverlay>
      <Site>
        {helmet}
        <Padding all={0}>
          {masthead}
          <VSpacer height={48} />
          <Page>{page}</Page>
        </Padding>
        <VSpacer height={64} />
        {footer}
      </Site>
    </Fragment>
  )
}

export default SinglePageApp
