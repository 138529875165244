export const roles = [
  {
    accounts: ['rec5xAq6CClE4T2oE', 'recmCJ0zwOsnoNNzv', 'rectiQruVVAX6TTjz', 'recJobaZWqkus6d5p', 'recwg1YVktabydyS6', 'recCKqe7MXx7JVBv5'],
    affiliation: ['recXTMFAyaZHCCqHD'],
    role_type: 'root',
    permissions: ['crud-affiliate', 'crud-account', 'crud-own'],
    users: ['recSX7j7LbnYqWKHU'],
    slug: 'micromega__root',
    _id: 'rec4x5m6sX9WitcjV'
  },
  {
    accounts: ['rec1KXFJWYqisujda', 'recga2zwUMbFlh4yv', 'reckaKC5QcyUbTXQa', 'reciy0qk1uAodJbNq', 'recTOr2J3qqBwmDGS'],
    affiliation: ['recvWEyI3yT9BW4Uj'],
    role_type: 'affiliate-admin',
    permissions: ['_ru_-affiliate', 'crud-account', 'crud-own'],
    users: ['recrxBMZfhnedNQrg'],
    slug: 'modyva__affiliate-admin',
    _id: 'recWhESvBQhWAoKWJ'
  },
  {
    accounts: ['rec1KXFJWYqisujda'],
    affiliation: ['recP7Ox8KIRghX2Bd'],
    role_type: 'account-admin',
    permissions: ['_ru__-account', 'crud-own'],
    users: ['recWLtKvvfz71g16o'],
    slug: 'lhoist__account-admin',
    _id: 'recv4PY3DyhlXjs2O'
  },
  {
    accounts: ['recga2zwUMbFlh4yv'],
    affiliation: ['recbBN1QL5PXV7C5Z'],
    role_type: 'account-admin',
    permissions: ['_ru__-account', 'crud-own'],
    slug: 'galere__account-admin',
    _id: 'recTuvPlNvLR47YAY'
  },
  {
    accounts: ['reckaKC5QcyUbTXQa'],
    affiliation: ['recMpGTUdRYlvc6cz'],
    role_type: 'account-admin',
    permissions: ['_ru__-account', 'crud-own'],
    slug: 'thomas-piron__account-admin',
    _id: 'recbUdI4S2SQO6fSG'
  },
  {
    accounts: ['reciy0qk1uAodJbNq'],
    affiliation: ['rec8azrVFEcZMZy8M'],
    role_type: 'account-admin',
    permissions: ['_ru__-account', 'crud-own'],
    slug: 'euro-com-museum__account-admin',
    _id: 'rec3zjBSl3supVrdH'
  },
  {
    accounts: ['recTOr2J3qqBwmDGS'],
    affiliation: ['recvWTkw5jCtRMHvT'],
    role_type: 'account-admin',
    permissions: ['_ru__-account', 'crud-own'],
    slug: 'edith-cavell-hospital__account-admin',
    _id: 'recrQQPCOkPQ1LN6z'
  },
  {
    accounts: ['rec1KXFJWYqisujda'],
    affiliation: ['recP7Ox8KIRghX2Bd'],
    role_type: 'resident',
    permissions: ['_r__-account', 'crud-own'],
    slug: 'lhoist__resident',
    _id: 'rec86csWr6koH1yzo'
  },
  {
    accounts: ['reckaKC5QcyUbTXQa'],
    affiliation: ['recMpGTUdRYlvc6cz'],
    role_type: 'resident',
    permissions: ['_r__-account', 'crud-own'],
    slug: 'thomas-piron__resident',
    _id: 'rec9PlnfOyxJLesXG'
  },
  {
    accounts: ['reclxrL0Cup5N6Pr0'],
    affiliation: ['reco2T4qY8k7ruPdZ'],
    role_type: 'account-admin',
    permissions: ['_ru__-account', 'crud-own'],
    slug: 'walibi-holland__account-admin',
    _id: 'recOOm0n4gxUVws2d'
  },
  {
    accounts: ['reclxrL0Cup5N6Pr0', 'recwnCRazGSgCOjxj'],
    affiliation: ['rec7IkhJXt0UCI4gy'],
    role_type: 'affiliate-admin',
    permissions: ['_ru_-affiliate', 'crud-account', 'crud-own'],
    users: ['recFdhdrZldFt0hLw'],
    slug: 'flow-engineering__affiliate-admin',
    _id: 'recAXFZv1FVRR7zjf'
  },
  {
    accounts: ['recwnCRazGSgCOjxj'],
    affiliation: ['recwTyR80oQHe691F'],
    role_type: 'account-admin',
    permissions: ['_ru__-account', 'crud-own'],
    slug: 'flagpole__account-admin',
    _id: 'recuH54acD7GGz1VT'
  },
  {
    accounts: ['recLyFFSsyqhuR7LE'],
    affiliation: ['recmwl0uYaUKl61Rj'],
    role_type: 'affiliate-admin',
    permissions: ['_ru_-affiliate', 'crud-account', 'crud-own'],
    users: ['reciIkEaPYyPvLw5R'],
    slug: 'gantrex__affiliate-admin',
    _id: 'rec5P6GryMhqOLxuv'
  },
  {
    accounts: ['recLyFFSsyqhuR7LE'],
    affiliation: ['recD5Wxo1tG9MJGJP'],
    role_type: 'account-admin',
    permissions: ['_ru__-account', 'crud-own'],
    slug: 'melbourne-heaven__account-admin',
    _id: 'recj7ejjLSYcUwDwS'
  },
  {
    accounts: ['recCKqe7MXx7JVBv5'],
    affiliation: ['rec5Dr2AzT38TCRlA'],
    role_type: 'account-admin',
    permissions: ['_ru__-account', 'crud-own'],
    slug: 'new-york-heaven__account-admin',
    _id: 'recZ1rJcUAHhvm8oQ'
  },
  {
    accounts: ['rec5xAq6CClE4T2oE'],
    affiliation: ['recO1AJ5fGJgnMkaV'],
    role_type: 'account-admin',
    permissions: ['_ru__-account', 'crud-own'],
    users: ['recSX7j7LbnYqWKHU', 'rec7LB6qqFX4BUwEf'],
    slug: 'pommeroeul__account-admin',
    _id: 'recAf0M5Ai2UaIRYy'
  }
]
