import React from 'react'
import Chart from 'react-apexcharts'

const defaults = {
  title: 'Peak Vibration (Pa)',
  colors: ['#008FFB'],
  tooltipValue: function(value) {
    return value.toFixed(5)
  },
  axisValue: function(value) {
    return value.toFixed(1)
  }
}

const optionsMain = {
  chart: {
    id: 'chartArea',
    toolbar: {
      autoSelected: 'zoom',
      show: true
    }
  },
  brush: {
    enabled: false
  },

  title: {
    text: 'Building Vibrations DIN4150-3/L2 VB2 (mm/s)',
    align: 'middle',
    offsetX: 0
  },
  animations: {
    enabled: false
  },
  markers: {
    size: 0
  },
  colors: ['#28A138', '#F9971E', '#3B5998'],
  stroke: {
    width: 1
  },
  tooltip: {
    enabled: true,
    interesect: false,
    fillSeriesColor: false,
    style: { fontSize: '0.6rem' },
    x: {
      show: true,
      format: 'dd MMM HH:mm:ss',
      formatter: undefined
    },
    y: {
      formatter: defaults.tooltipValue,
      title: {
        formatter: d => d
      }
    }
  },
  dataLabels: {
    enabled: false
  },
  fill: {
    opacity: 1
  },
  xaxis: {
    type: 'datetime'
  },
  yaxis: {
    min: 0,
    max: 1,
    labels: {
      show: true,
      formatter: function(value) {
        return (value || 0).toFixed(1)
      }
    }
  },
  annotations: {
    yaxis: []
  }
}
const optionsBrush = {
  chart: {
    id: 'chartBrush',
    brush: {
      target: 'chartArea',
      enabled: true
    },
    animations: {
      enabled: false
    },
    markers: {
      size: 0
    },
    selection: {
      enabled: true
    }
  },
  colors: ['#008FFB'],
  stroke: {
    width: 1
  },
  fill: {
    opacity: 0
  },
  xaxis: {
    type: 'datetime',
    tooltip: {
      enabled: false
    }
  },
  yaxis: {
    tickAmount: 2,
    min: 0,
    labels: {
      show: true,
      formatter: function(value) {
        return value.toFixed(1)
      }
    }
  }
}

const roundDec = (d, pow = 2) => {
  const dec = Math.pow(10, pow)
  return Math.round(d * dec) / dec
}

const TimeSeries = props => {
  const { x, y, z } = props

  if (!x) {
    return null
  }

  //   console.log('SERIES', JSON.stringify(timeseries))

  const seriesX = x.map(d => {
    const { v, time } = d
    return [time, roundDec(v, 5)]
  })
  const seriesY = y.map(d => {
    const { v, time } = d
    return [time, roundDec(v, 5)]
  })
  const seriesZ = z.map(d => {
    const { v, time } = d
    return [time, roundDec(v, 5)]
  })
  // console.log('SERIES', JSON.stringify(seriesX))

  optionsMain.yaxis.max = 7 // maxPoint
  optionsBrush.yaxis.max = 7 // maxPoint

  const maxStd = 5
  const line = { color: '#0071ca', dash: 3 } // maxPoint >= maxStd ? { color: '#b22200', dash: 3 } : { color: '#3ca53a', dash: 0 }
  optionsMain.annotations.yaxis = [
    {
      y: maxStd,
      strokeDashArray: line.dash,
      borderColor: line.color,
      label: {
        borderColor: line.color,
        style: {
          color: '#fff',
          background: line.color
        },
        text: `${maxStd} mm/s`
      }
    }
  ]

  return (
    <div>
      <div id="charts">
        <div id="chart1">
          <Chart
            options={optionsMain}
            series={[{ name: 'X', data: seriesX }, { name: 'Y', data: seriesY }, { name: 'Z', data: seriesZ }]}
            type="line"
            height="360"
          />
        </div>
        <div id="chart2">
          <Chart options={optionsBrush} series={[{ name: 'X', data: seriesX }]} type="area" height="130" />
        </div>
      </div>
    </div>
  )
}

export default TimeSeries
