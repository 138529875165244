import { connect } from 'react-redux'
import { fetcher } from './fetcher'
import { factories } from './factory'
const buildFullId = ({ streamId, lensId }) => {
  return `${streamId}__${lensId}`
}

class StreamsManager {
  constructor() {
    this.state = { timeRange: undefined, streams: {} }
  }
  setTimeRange(d) {
    if (this.state.timeRange !== d) {
      this.state.timeRange = d
      // this.state.streams = {}
    }
  }
  getStreamData({ streamId, lensId }) {
    const fullId = buildFullId({ streamId, lensId })
    const data = this.state.streams[fullId] || {}
    return this.state.streams[fullId]
  }
  setStreamData({ streamId, lensId }, data) {
    const fullId = buildFullId({ streamId, lensId })
    if (Array.isArray(data)) {
      let maxPoint = 0
      const datapoints = (data || []).map(d => {
        const { v, time } = d
        const f = parseFloat(v)
        if (maxPoint < f) {
          maxPoint = f
        }
        return [time, f]
      })
      data = { maxPoint, datapoints }
    }

    this.state.streams[fullId] = data
  }
  setStreamError({ streamId, lensId }, err) {
    this.setStreamData({ streamId, lensId }, { error: err })
  }
  setStreamLoading({ streamId, lensId }, data) {
    this.setStreamData({ streamId, lensId }, { loading: true })
  }

  loadStreamData(dispatch, streamId, lensId, timeRange) {
    const mgr = this
    mgr.setStreamLoading({ streamId, lensId })
    dispatch({ type: 'STREAM_DATA_CHANGE', payload: Date.now() })
    fetcher(
      { timeRange, streamId, lensId },
      err => {
        mgr.setStreamError({ streamId, lensId }, err)
        dispatch({ type: 'STREAM_DATA_CHANGE', payload: Date.now() })
      },
      data => {
        mgr.setStreamData({ streamId, lensId }, data)
        dispatch({ type: 'STREAM_DATA_CHANGE', payload: Date.now() })
      }
    )
  }
}

let streamsMgr
const guaranteeStreamsMgr = () => {
  if (!streamsMgr) {
    streamsMgr = new StreamsManager()
  }
  return streamsMgr
}

const connector = connect(
  state => {
    const { activeLens: item, timeRange, streamLastUpdate } = state
    if (item) {
      const streamsMgr = guaranteeStreamsMgr()
      const streamsData = (item.streams || []).reduce((acc, d) => {
        acc[d.slug] = streamsMgr.getStreamData({ lensId: item.slug, streamId: d.slug })
        return acc
      }, {})
      item.streams = item.streams.map(d => {
        return { ...d, WidgetFactory: factories(d.slug) }
      })
      item.streamsData = streamsData
    }
    return { item, timeRange, streamLastUpdate }
  },
  (dispatch, ownProps) => {
    const { store } = ownProps
    return {
      whenExit: () => {
        dispatch({ type: 'EXIT_LENS_DASHBOARD' })
      },
      whenTimeRangeChange: timeRange => {
        const streamsMgr = guaranteeStreamsMgr()
        streamsMgr.setTimeRange(timeRange)
        dispatch({ type: 'TIME_RANGE_CHANGE', payload: timeRange })
      },
      whenAccountActive: activeAccount => {
        dispatch({ type: 'ACCOUNT_ACTIVE_CHANGE', payload: activeAccount })
      },
      whenLoadStream: streamId => {
        const { activeLens, timeRange } = store.getState()
        const streamsMgr = guaranteeStreamsMgr()
        streamsMgr.loadStreamData(dispatch, streamId, activeLens.slug, timeRange)
      }
    }
  }
)

export default connector
