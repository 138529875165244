export const data_streams = [
  {
    lenses: 'lhoist-quarry__blasting',
    setups: [
      'recQcTeyazga3IYdm',
      'recCYQp3L7J4vA8Gp',
      'recYZeXh7nwxqmfEp',
      'recciR5HmT4NnwUul',
      'recDiDqnuQ5Q5JAk9',
      'rec2Zn98UtyJKuUMN',
      'recZkkeVpMkqArX0u',
      'recMvlAtd6vPzCsQE',
      'recJQXY4iUPhZMRQV',
      'recKXkaMCsW3OvTO1',
      'recqOpBVBW3dRJou9',
      'rec3m32p5wq4un9We',
      'recInyAZCmqwmU85K',
      'rec8k1Juv9k4Napbv',
      'recO3J0JmGBHLxHFD',
      'recOnceAH3FyM4Uj8',
      'recC1Xr5JFgo0EMzy'
    ],
    layouts: 'air-vib',
    message_packets: ['reco0k90t2QV8wj9h'],
    i18n: 'Building Vibration according to DIN4150-3/L2',
    'accounts copy': ['recIOJpvN1aODyJYL'],
    slug: 'lho-1-vib',
    _id: 'reca6za0b0tgTht9L'
  },
  {
    lenses: 'lhoist-quarry__blasting',
    setups: [
      'recCYQp3L7J4vA8Gp',
      'recTreWvq0Er2W49U',
      'recsOtJyHlHQDljF1',
      'rec2Zn98UtyJKuUMN',
      'recZkkeVpMkqArX0u',
      'recMvlAtd6vPzCsQE',
      'recJQXY4iUPhZMRQV',
      'recKXkaMCsW3OvTO1',
      'recqOpBVBW3dRJou9',
      'rec3m32p5wq4un9We',
      'recEqzCEbFib3PBwt',
      'recxHVfV715agcSSL',
      'recYZeXh7nwxqmfEp',
      'recOnceAH3FyM4Uj8',
      'recjVflqraPd3OJrv'
    ],
    layouts: 'air-vib',
    i18n: 'Air pressure (infrasound from 2Hz to 250Hz).',
    'accounts copy': ['recbhTePz1FBmCE3M'],
    slug: 'lho-2-air',
    _id: 'recLxhIA6Bnvt1xcG'
  },
  {
    lenses: 'bierset-ground-radar__construction-vibs',
    setups: ['recQcTeyazga3IYdm', 'recdrW9Sa6UeqYXrI', 'recciR5HmT4NnwUul', 'recO3J0JmGBHLxHFD', 'recOnceAH3FyM4Uj8', 'recC1Xr5JFgo0EMzy'],
    i18n: 'Warning and Alarms (actions vs stop work)',
    devices: ['recJRVpuX5UNAYEaz'],
    'accounts copy': ['recqHY8CxPqYfppo7'],
    slug: 'bie-1-vib',
    _id: 'recvJ8iTYK3juJ5h8'
  },
  {
    lenses: 'thomas-piron-resident__construction-vibs',
    setups: [
      'recQcTeyazga3IYdm',
      'recIB9O3EsqDIf9W2',
      'recciR5HmT4NnwUul',
      'recInyAZCmqwmU85K',
      'rec8k1Juv9k4Napbv',
      'recO3J0JmGBHLxHFD',
      'recOnceAH3FyM4Uj8',
      'recC1Xr5JFgo0EMzy'
    ],
    i18n: 'Monitoring following DIN4150-3/L2 (normal buildings). Comparison with ACOEM Orion ',
    'accounts copy': ['recuHGbbtfNd51iGM'],
    slug: 'thp-1-vib',
    _id: 'recSBNeipTz37MEqd'
  },
  {
    lenses: 'thomas-piron-resident__construction-vibs',
    setups: [
      'recQcTeyazga3IYdm',
      'recIB9O3EsqDIf9W2',
      'recciR5HmT4NnwUul',
      'recInyAZCmqwmU85K',
      'rectkjHBNrIq8bjX4',
      'recO3J0JmGBHLxHFD',
      'recOnceAH3FyM4Uj8',
      'recC1Xr5JFgo0EMzy'
    ],
    i18n: 'Monitoring following DIN4150-3/L3 (sensitive buildings). Comparison with ACOEM Orion.',
    'accounts copy': ['recuHGbbtfNd51iGM'],
    slug: 'thp-2-vib',
    _id: 'rec8P9nhHyXsIUvNX'
  },
  {
    lenses: 'ec-museum__construction-vibs+construction-dust',
    setups: [
      'recQcTeyazga3IYdm',
      'recm4SnpwlU3q0VBm',
      'recxcTak7rBuOMSdh',
      'rec7TaUagTqP1LtJr',
      'recJQXY4iUPhZMRQV',
      'recqOpBVBW3dRJou9',
      'recKXkaMCsW3OvTO1',
      'recMvlAtd6vPzCsQE',
      'recciR5HmT4NnwUul',
      'recJVIKy2fu2LYuHX',
      'recDTEN1OoEBpmj9z',
      'recJdyxzz0lJ9y2kw',
      'recOnceAH3FyM4Uj8',
      'rec8gP81biV5ydh7e',
      'rectkjHBNrIq8bjX4'
    ],
    i18n: 'Building vibration according to DIN4150-3/L3 (sensitive buildings). ',
    'accounts copy': ['recs5WZqExPH7RwD2'],
    slug: 'ecm-1-vib',
    _id: 'recoPHTnfctQNZZj9'
  },
  {
    lenses: 'ec-museum__construction-vibs+construction-dust',
    setups: [
      'recm4SnpwlU3q0VBm',
      'recxcTak7rBuOMSdh',
      'rec7TaUagTqP1LtJr',
      'recJQXY4iUPhZMRQV',
      'recMvlAtd6vPzCsQE',
      'recqOpBVBW3dRJou9',
      'recKXkaMCsW3OvTO1',
      'recL9iJ5j0ThDn69x',
      'recJVIKy2fu2LYuHX',
      'recDTEN1OoEBpmj9z',
      'recJdyxzz0lJ9y2kw',
      'recOnceAH3FyM4Uj8',
      'rec8gP81biV5ydh7e',
      'recwptm37119EI4oz'
    ],
    i18n: 'Voltage input for a Casella Dust Monitoring Device. ',
    'accounts copy': ['recs5WZqExPH7RwD2'],
    slug: 'ecm-2-dst',
    _id: 'recVrFS2OsKkRqW3O'
  },
  {
    lenses: 'edith-cavel-hospital__construction-vibs',
    setups: [
      'recKXkaMCsW3OvTO1',
      'recdrW9Sa6UeqYXrI',
      'recP2Ub2YEUB4l2Ss',
      'recDTEN1OoEBpmj9z',
      'recJQXY4iUPhZMRQV',
      'recqOpBVBW3dRJou9',
      'recciR5HmT4NnwUul',
      'recxHVfV715agcSSL',
      'recInyAZCmqwmU85K',
      'rectkjHBNrIq8bjX4'
    ],
    i18n: 'Building Vibration Measurement according to DIN4150-3/L3',
    'accounts copy': ['rec3lnBPGtFUquYwu'],
    slug: 'ecv-1-vib',
    _id: 'rec91LQaRUP2YO0zn'
  },
  {
    lenses: 'walibi-nl-roller-coaster__damping-tmd',
    i18n: 'Accelerometer #1 on the Roller Coaster Structure ',
    'accounts copy': ['recv4nk6fxEoHeahC'],
    slug: 'wnl-1-tmd',
    _id: 'recxPkJjvFX9ep40E'
  },
  {
    lenses: 'walibi-nl-roller-coaster__damping-tmd',
    i18n: 'Accelerometer #2 on the Roller Coaster Structure ',
    'accounts copy': ['recv4nk6fxEoHeahC'],
    slug: 'wnl-2-tmd',
    _id: 'recWYMGM5POyNd7Wp'
  },
  {
    lenses: 'walibi-nl-roller-coaster__damping-tmd',
    setups: [
      'recJ8OcWfMyhzxyQl',
      'recZkkeVpMkqArX0u',
      'recJQXY4iUPhZMRQV',
      'recMvlAtd6vPzCsQE',
      'recqOpBVBW3dRJou9',
      'recKXkaMCsW3OvTO1',
      'recInyAZCmqwmU85K',
      'recxHVfV715agcSSL',
      'recN9iLlqCPRIqpGB'
    ],
    i18n: 'Accelerometer on the TMD moving mass  ',
    'accounts copy': ['recv4nk6fxEoHeahC'],
    slug: 'wnl-3-tmd',
    _id: 'recC1RY9E9pMDfbzO'
  },
  {
    lenses: 'flagpole__structural-integrity+damping-tmd',
    i18n: 'Accelerations measurements on Flagpole',
    'accounts copy': ['recGUyqgcJ7zwWEnV'],
    slug: 'fgp-1-acc',
    _id: 'recWq8AdtvfzgbBdh'
  },
  {
    lenses: 'flagpole__structural-integrity+damping-tmd',
    i18n: 'Displacements measurements  on Flagpole',
    'accounts copy': ['recGUyqgcJ7zwWEnV'],
    slug: 'fgp-2-dis',
    _id: 'recsb04BUGn5GdJZE'
  },
  {
    lenses: 'flagpole__structural-integrity+damping-tmd',
    i18n: 'Dedicated algorithm for TMDs efficiency computations. ',
    'accounts copy': ['recGUyqgcJ7zwWEnV'],
    slug: 'fgp-1-tmd',
    _id: 'recdE45STLnoRQXoP'
  },
  {
    lenses: 'melb-heaven-rails__condition-monitoring',
    setups: ['recNRjUptB77u6OYG', 'rec8gP81biV5ydh7e', 'recvXjSEG11yeQlDc', 'recOnceAH3FyM4Uj8'],
    i18n: 'One string potentiometer sensor per rail. ',
    'accounts copy': ['recV5BeY5BFAoZsBg'],
    slug: 'mbh-1-pot',
    _id: 'reczQrLzwcnhs9ai2'
  },
  {
    lenses: 'melb-heaven-rails__condition-monitoring',
    setups: ['recNRjUptB77u6OYG', 'rec8gP81biV5ydh7e', 'recvXjSEG11yeQlDc', 'recOnceAH3FyM4Uj8'],
    i18n: 'One string potentiometer sensor per rail. ',
    'accounts copy': ['recV5BeY5BFAoZsBg'],
    slug: 'mbh-2-pot',
    _id: 'rec4aijszCw0NNeaj'
  },
  {
    lenses: 'melb-heaven-rails__condition-monitoring',
    setups: ['recNRjUptB77u6OYG', 'rec8gP81biV5ydh7e', 'recvXjSEG11yeQlDc', 'recOnceAH3FyM4Uj8'],
    i18n: 'One string potentiometer sensor per rail. ',
    'accounts copy': ['recV5BeY5BFAoZsBg'],
    slug: 'mbh-3-pot',
    _id: 'recMAYnjeShJ2TU6y'
  },
  {
    lenses: 'melb-heaven-rails__condition-monitoring',
    setups: ['recmYuW3p3rERtVZt', 'rec8gP81biV5ydh7e', 'recvXjSEG11yeQlDc', 'recOnceAH3FyM4Uj8'],
    i18n: 'One surface temperature sensor per rail. ',
    'accounts copy': ['recV5BeY5BFAoZsBg'],
    slug: 'mbh-12-tem',
    _id: 'reciy3WiVuSK6TURe'
  },
  {
    lenses: 'melb-heaven-rails__condition-monitoring',
    setups: ['recmYuW3p3rERtVZt', 'rec8gP81biV5ydh7e', 'recvXjSEG11yeQlDc', 'recOnceAH3FyM4Uj8'],
    i18n: 'One surface temperature sensor per rail. ',
    'accounts copy': ['recV5BeY5BFAoZsBg'],
    slug: 'mbh-14-tem',
    _id: 'recvzchb0VfGSJgjA'
  },
  {
    lenses: 'melb-heaven-rails__condition-monitoring',
    setups: ['recmYuW3p3rERtVZt', 'rec8gP81biV5ydh7e', 'recvXjSEG11yeQlDc', 'recOnceAH3FyM4Uj8'],
    i18n: 'One surface temperature sensor per rail. ',
    'accounts copy': ['recV5BeY5BFAoZsBg'],
    slug: 'mbh-15-tem',
    _id: 'recGgDJqZ5HE0Vvcw'
  },
  {
    lenses: 'ny-heaven-rails__condition-monitoring',
    setups: ['recEqzCEbFib3PBwt', 'recOnceAH3FyM4Uj8', 'recxHVfV715agcSSL'],
    i18n: 'Warnings and alarms if vibration exceeds given levels. Periodic bursts for FFT computations ',
    'accounts copy': ['recMhmNdp0MqD3WlH'],
    slug: 'nyh-1-vib',
    _id: 'recquF6DJuIrwwJdC'
  },
  {
    lenses: 'gsm-pylon-structural-integrity__structural-integrity',
    setups: ['recIB9O3EsqDIf9W2', 'recOnceAH3FyM4Uj8', 'recxHVfV715agcSSL'],
    i18n: 'Operating temperature range for sensors (from -40°C, -55°C in a few places). ',
    'accounts copy': ['recGNXx1XwpuslTII'],
    slug: 'gsm-1-tem',
    _id: 'recOrTN86YJochoOW'
  },
  {
    lenses: 'wind-turbine__structural-integrity',
    setups: ['rec8gP81biV5ydh7e', 'recOnceAH3FyM4Uj8'],
    i18n: '2-axis IAC-I accelerometer',
    'accounts copy': ['recTV7J5ztzNmeyV1'],
    slug: 'wnd-1-acc',
    _id: 'recEwPg9XshyyWtu7'
  },
  {
    lenses: 'cooling-system__condition-monitoring',
    setups: ['recEqzCEbFib3PBwt', 'rec4t8JtY53tEO2OO', 'recOnceAH3FyM4Uj8', 'rec0dgLaShzKrLDcr', 'rec8gP81biV5ydh7e', 'recOtJJKpvT37z9HP'],
    i18n: 'Remote monitoring from everywhere. Up to 500Hz useful bandwidth ',
    'accounts copy': ['recDPM0AyYPg01e9b'],
    slug: 'coo-1-cnd',
    _id: 'recyMINUTVLCzz2qL'
  },
  {
    lenses: 'cooling-system__condition-monitoring',
    setups: ['recEqzCEbFib3PBwt', 'rec4t8JtY53tEO2OO', 'recOnceAH3FyM4Uj8', 'rec0dgLaShzKrLDcr', 'rec8gP81biV5ydh7e', 'recOtJJKpvT37z9HP'],
    i18n: 'Remote monitoring from everywhere. Up to 500Hz useful bandwidth ',
    'accounts copy': ['recDPM0AyYPg01e9b'],
    slug: 'coo-2-cnd',
    _id: 'recTJvvnO1tAK4kpS'
  },
  {
    lenses: 'cooling-system__condition-monitoring',
    setups: ['recEqzCEbFib3PBwt', 'rec4t8JtY53tEO2OO', 'recOnceAH3FyM4Uj8', 'rec0dgLaShzKrLDcr', 'rec8gP81biV5ydh7e', 'recOtJJKpvT37z9HP'],
    i18n: 'Remote monitoring from everywhere. Up to 500Hz useful bandwidth ',
    'accounts copy': ['recDPM0AyYPg01e9b'],
    slug: 'coo-3-cnd',
    _id: 'recBi8lw71cH5EME9'
  },
  {
    lenses: 'cooling-system__condition-monitoring',
    setups: ['recEqzCEbFib3PBwt', 'rec4t8JtY53tEO2OO', 'recOnceAH3FyM4Uj8', 'rec0dgLaShzKrLDcr', 'rec8gP81biV5ydh7e', 'recOtJJKpvT37z9HP'],
    i18n: 'Remote monitoring from everywhere. Up to 500Hz useful bandwidth ',
    'accounts copy': ['recDPM0AyYPg01e9b'],
    slug: 'coo-4-cnd',
    _id: 'recPPHY6AWuFGyh1j'
  },
  {
    lenses: 'pagoda__structural-integrity',
    setups: ['rec3DxqVytUlKlmir', 'recOnceAH3FyM4Uj8', 'recxHVfV715agcSSL', 'recBRoBRRv61gmCvT'],
    i18n:
      'Synchronized measurement across acceleration channels  Periodical bursts for structural analysis. 3 x 2-axis accelerometers at 3 different levels on the pagoda (I.e. long cables). ',
    'accounts copy': ['recw9FzF9RHGiV8p7'],
    slug: 'pgd-1-acc',
    _id: 'recVgyOlCT82uu00F'
  },
  {
    lenses: 'pagoda__structural-integrity',
    setups: ['rec3DxqVytUlKlmir', 'recOnceAH3FyM4Uj8', 'recxHVfV715agcSSL', 'recBRoBRRv61gmCvT'],
    i18n:
      'Synchronized measurement across acceleration channels  Periodical bursts for structural analysis. 3 x 2-axis accelerometers at 3 different levels on the pagoda (I.e. long cables). ',
    'accounts copy': ['recw9FzF9RHGiV8p7'],
    slug: 'pgd-2-acc',
    _id: 'reccSXMPSO5FNs5QQ'
  },
  {
    lenses: 'pagoda__structural-integrity',
    setups: ['rec3DxqVytUlKlmir', 'recOnceAH3FyM4Uj8', 'recxHVfV715agcSSL', 'recBRoBRRv61gmCvT'],
    i18n:
      'Synchronized measurement across acceleration channels  Periodical bursts for structural analysis. 3 x 2-axis accelerometers at 3 different levels on the pagoda (I.e. long cables). ',
    'accounts copy': ['recw9FzF9RHGiV8p7'],
    slug: 'pgd-3-acc',
    _id: 'recjsrR35WaSNtMvK'
  },
  {
    lenses: 'pagoda__structural-integrity',
    setups: ['rec3DxqVytUlKlmir', 'recOnceAH3FyM4Uj8', 'recxHVfV715agcSSL', 'recBRoBRRv61gmCvT'],
    i18n:
      'Synchronized measurement across acceleration channels  Periodical bursts for structural analysis. 3 x 2-axis accelerometers at 3 different levels on the pagoda (I.e. long cables). ',
    'accounts copy': ['recw9FzF9RHGiV8p7'],
    slug: 'pgd-4-acc',
    _id: 'rechGRZ9PkhxxtL8P'
  },
  {
    lenses: 'pagoda__structural-integrity',
    setups: ['rec3DxqVytUlKlmir', 'recOnceAH3FyM4Uj8', 'recxHVfV715agcSSL', 'recBRoBRRv61gmCvT'],
    i18n: '3 x inclinometer (without temperature compensation)',
    'accounts copy': ['recw9FzF9RHGiV8p7'],
    slug: 'pgd-20-inc',
    _id: 'reckEBCIbOJBz1hAV'
  },
  {
    lenses: 'pagoda__structural-integrity',
    setups: ['rec3DxqVytUlKlmir', 'recOnceAH3FyM4Uj8', 'recxHVfV715agcSSL', 'recBRoBRRv61gmCvT'],
    i18n: '3 x inclinometer (without temperature compensation)',
    'accounts copy': ['recw9FzF9RHGiV8p7'],
    slug: 'pgd-21-inc',
    _id: 'recJ06o66XKyj2lhz'
  },
  {
    lenses: 'pagoda__structural-integrity',
    setups: ['rec3DxqVytUlKlmir', 'recOnceAH3FyM4Uj8', 'recxHVfV715agcSSL', 'recBRoBRRv61gmCvT'],
    i18n: '3 x inclinometer (without temperature compensation)',
    'accounts copy': ['recw9FzF9RHGiV8p7'],
    slug: 'pgd-22-inc',
    _id: 'reczu9EMYTMbotMVP'
  },
  {
    lenses: 'pommeroeul-railways__construction-vibs',
    setups: ['rec8k1Juv9k4Napbv', 'recO3J0JmGBHLxHFD', 'recEqzCEbFib3PBwt', 'recOnceAH3FyM4Uj8', 'recC1Xr5JFgo0EMzy'],
    i18n: 'DIN4150-3/L2 with 1-250Hz bandpass filter ',
    'accounts copy': ['recf4wZcfFAXY1neg'],
    slug: 'pom-1-vib',
    _id: 'recCaPYRzVpL22b5U'
  }
]
