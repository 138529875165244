import React from 'react'
import styled from 'styled-components'

import { Helmet } from 'react-helmet'
import SinglePageApp from 'design-system/single-page-app/'
import LoginForm from 'design-system/login-form/'

import Masthead from 'design-system-mmd/recovibcloud-masthead/'
import Footer from 'design-system-mmd/recovibcloud-footer/'
// import CookieNotice from 'design-system-mmd/cookie-notice/'

export const i18n = { appTitle: 'Recovib Cloud' }

export const PageTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 8px;
`

export const PageSubheading = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 4px;
`

// browser window head

const getFragments = props => {
  const { whenAuthenticateAttempt, authError, activeRoute, branding } = props
  return {
    helmet: (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{i18n.appTitle}</title>
        <link rel="canonical" href="http://recovib-cloud.com" />
        <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
      </Helmet>
    ),
    masthead: <Masthead branding={branding} padding={8} />,
    page: !authError ? activeRoute : <LoginForm {...{ whenAuthenticateAttempt, authError }} Skins={{ FormTitle: PageTitle, FormSubheading: PageSubheading }} />,
    footer: <Footer />
  }
}

const RecovibSite = props => {
  return <SinglePageApp {...getFragments(props)} />
}

export default RecovibSite
