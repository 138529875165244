import styled from 'styled-components'

export const hgroup = {
  display: 'flex',
  flexDirection: 'row'
}
export const vgroup = {
  display: 'flex',
  flexDirection: 'column'
}
export const HBox = styled.div([hgroup])
export const VBox = styled.span([vgroup])

export const InlineHOC = d => {
  return styled(d)`
    display: inline-block;
    flex: 0 0 auto;
  `
}

export const Inline = styled.div`
  display: inline-block;
  flex: 0 0 auto;
`

export const HSpacer = styled.span`
  display: inline-block;
  width: ${props => props.width || 0}px;
`

export const VSpacer = styled.div`
  height: ${props => props.height || 0}px;
`

export const Padding = styled.div`
  padding: ${props => props.all || 0}px;
`
