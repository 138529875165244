import React, { Component, Fragment } from 'react'
import { View, Text, Inline, HSpacer } from 'design-system/'
import moment from 'moment'

import Button from 'design-system/button'

const l10n = { weekType: 'isoWeek' }

const pastWeek = (end, n) => {
  const { weekType } = l10n
  return [
    moment(end)
      .subtract(n, 'week')
      .startOf(weekType),
    moment.min([
      moment(end)
        .subtract(n, 'week')
        .endOf(weekType),
      moment(end)
    ])
  ]
}

const formatDateRange = timeRange => {
  if (!timeRange) {
    return ''
  }
  return `${moment(timeRange.start).format('DD MMM YYYY')} - ${moment(timeRange.end).format('DD MMM YYYY')}`
}

const buildRanges = () => {
  const now = new Date()
  const { weekType } = l10n
  const start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0))
  const end = moment(start)
    .add(1, 'days')
    .subtract(1, 'seconds')

  const ranges = [
    { i18n: 'Today', range: [moment(start), moment(end)] },
    { i18n: 'Yesterday', range: [moment(start).subtract(1, 'days'), moment(end).subtract(1, 'days')] },
    { i18n: 'Last 3 Days', range: [moment(start).subtract(3, 'days'), moment(end)] },
    { i18n: 'This week', range: [moment(end).startOf(weekType), moment.min([moment(end).endOf(weekType), moment(end)])] },
    { i18n: 'Last week', range: pastWeek(end, 1) }
  ].map(({ i18n, range }) => {
    const [start, end] = range
    return { i18n, range: [start.toISOString(), end.toISOString()] }
  })
  return { ranges, startOfToday: start, endOfToday: end }
}

class WeeksWithMonth extends Component {
  constructor(props) {
    super(props)
    this.bound = {
      onRangeSelected: this.onRangeSelected.bind(this)
    }
  }
  onRangeSelected(event) {
    const { range } = event.currentTarget.dataset
    this.signalChange(...range.split(','))
  }

  signalChange(startDate, endDate) {
    const { whenChange } = this.props
    if (typeof whenChange === 'function') {
      whenChange({ start: moment(startDate).toISOString(), end: moment(endDate).toISOString() })
    }
  }

  render() {
    const { end, firstDate } = this.props
    const { onRangeSelected } = this.bound

    const weeks = [...Array(10)]
      .map((d, i) => {
        return i + 2
      })
      .map(d => {
        return { i18n: `Week -${d}`, range: pastWeek(end, d) }
      })
      .filter(d => {
        const [start, end] = d.range
        const days = moment(start).diff(firstDate, 'days')
        return days >= -7
      })

    return (
      <View>
        {weeks.map(d => {
          const { i18n, range } = d
          const [start] = range
          const date = moment(start).date()
          const month = moment(start).format('MMM')

          return (
            <Button key={i18n} onClick={onRangeSelected} data-range={range}>
              {date} {month}
            </Button>
          )
        })}
      </View>
    )
  }
}

class WeekRangePicker extends Component {
  constructor(props) {
    super(props)
    let { timeRange, firstDate } = props
    const { ranges, startOfToday, endOfToday } = buildRanges()
    const lastInRange = (ranges.slice(-1).pop() || {}).range[1]

    const weeksDiff = moment(lastInRange).diff(moment(firstDate), 'weeks')
    const olderHidden = weeksDiff <= 0
    let active = undefined

    this.state = {
      ranges,
      startOfToday,
      endOfToday,
      olderHidden,
      olderVisible: false,
      active
    }

    if (!timeRange || !timeRange.start) {
      this.state.active = 'Today'
      this.signalChange(startOfToday.toISOString(), endOfToday.toISOString())
    } else if (moment(timeRange.end) < endOfToday) {
      this.state.active = 'Today'
      this.signalChange(startOfToday.toISOString(), endOfToday.toISOString())
    } else if (moment(timeRange.start) < startOfToday) {
      this.signalChange(startOfToday.toISOString(), timeRange.end.toISOString())
    }

    this.bound = {
      onRangeSelected: this.onRangeSelected.bind(this),
      onOlderClick: this.onOlderClick.bind(this)
    }
  }

  onRangeSelected(event) {
    const { range, i18n } = event.currentTarget.dataset || {}
    const [startDate, endDate] = (range || '').split(',')
    const { start, end } = { start: moment(startDate).toISOString(), end: moment(endDate).toISOString() }
    this.setState({ active: i18n, timeRange: { start, end } })
    this.signalChange(...[start, end])
  }
  onOlderClick(event) {
    this.setState({ olderVisible: !this.state.olderVisible })
  }
  signalChange(startDate, endDate) {
    const { whenChange } = this.props
    if (typeof whenChange === 'function') {
      whenChange({ start: startDate, end: endDate })
    }
  }

  render() {
    const { timeRange, whenChange, firstDate } = this.props
    const { ranges, endOfToday, olderVisible, olderHidden, active } = this.state
    const { onRangeSelected, onOlderClick } = this.bound

    if (!timeRange) {
      return <View>No</View>
    }
    return (
      <Fragment>
        <Inline>
          {ranges.map(d => {
            const { i18n, range } = d
            const isActive = (active || '').toString() === i18n ? true : undefined
            return (
              <Button key={i18n} onClick={onRangeSelected} data-range={range} primary={isActive} data-i18n={i18n}>
                {i18n}
              </Button>
            )
          })}
          <HSpacer width={32} />
          {!olderHidden && <Text onClick={onOlderClick}>{!olderVisible ? 'Show' : 'Hide'} Older</Text>}
        </Inline>
        {olderVisible ? <WeeksWithMonth end={endOfToday} whenChange={whenChange} firstDate={firstDate} /> : null}
        <View>{formatDateRange(timeRange)}</View>
      </Fragment>
    )
  }
}
export default WeekRangePicker
