import React, { PureComponent as Component, Fragment } from 'react'
// Base
import { View, VSpacer } from 'design-system'

class WidgetProblems extends Component {
  render() {
    const { loading, error, nodata } = this.props
    let fragment = null
    if (error) {
      fragment = <View>Error, we could not load the data. Try reloading the page and please let us know if this problem repeats.</View>
    } else if (loading) {
      fragment = <View>Loading...</View>
    } else if (nodata) {
      fragment = <View>No Data Found for that period</View>
    }
    return fragment
  }
}

export default WidgetProblems
