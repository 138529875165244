export const icons = [
  {
    slug: 'algorithm',
    img: [
      {
        id: 'attQOL57neXxR0Pjh',
        url: 'https://dl.airtable.com/.attachments/1f2d40edb01049f4519982a925c0a3e2/2ddfd623/algorithm.png',
        filename: 'algorithm.png',
        size: 13122,
        type: 'image/png',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/55c2cf81b58eb8dcd63726709afc40a8/121a638e',
            width: 44,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/75a4e62dad96fc803e4b8845e2243a23/e7df7828',
            width: 444,
            height: 360
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/4343390e1bb7a5625dd404933c8a9dd6/73634ea6',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'rec9vbJh0NBvClAyD'
  },
  {
    slug: 'acquisition',
    img: [
      {
        id: 'attdSIBeDSFFQV2HP',
        url: 'https://dl.airtable.com/.attachments/41681e4e3dff99bb027ca4b22c194e92/e065e8eb/916806.png',
        filename: '916806.png',
        size: 11064,
        type: 'image/png',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/daef87ecc64e85f7e2bb11df3b2a388b/fa9bbe50',
            width: 41,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/8c41ab255f365b594bbed7712c0327d7/592c80ee',
            width: 480,
            height: 418
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/603fea8c91744f3b82ef2a4b70a304c0/f14557b3',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'recoWAgIxUPQDluSh'
  },
  {
    slug: 'comm',
    img: [
      {
        id: 'attjZABWqlYlx3Kph',
        url: 'https://dl.airtable.com/.attachments/eb17296a7a83649d1809e6499d3072f1/84913d19/comm.png',
        filename: 'comm.png',
        size: 4100,
        type: 'image/png',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/24a5356cc004b0ee824fa169d60ab751/74928080',
            width: 43,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/c49d64444ecdf44d1b49d17d282f5803/72294e38',
            width: 170,
            height: 144
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/7cd99532547f82ef38a40788409d1265/d493b53a',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'rec3NLVoSZuWx50lH'
  },
  {
    slug: 'deployment',
    img: [
      {
        id: 'attrXkJseOz1Ij5j4',
        url: 'https://dl.airtable.com/.attachments/2a4fb3c5ad721a9deda37dfc324e37ac/dac17aa8/deployment.png',
        filename: 'deployment.png',
        size: 23307,
        type: 'image/png',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/34004686e20d727667b05e66595a8c66/4c3aeffb',
            width: 37,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/83a6776f53423a849322b2d21aa7206f/9f77128a',
            width: 494,
            height: 480
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/35a46e8496dd8a095047e927e032f279/b0662296',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'recQpXjO0CvwxjlWf'
  },
  {
    slug: 'sensor',
    img: [
      {
        id: 'attkoRHmVSNgZbYxd',
        url: 'https://dl.airtable.com/.attachments/60e58d7aec2dca3d8fbbc5dd700972c1/a5b920d6/in.png',
        filename: 'in.png',
        size: 13003,
        type: 'image/png',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/5237a3bf568431c56c56747a04d77050/99df57cd',
            width: 59,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/81cd20c7854ebaa9bf326858d3f44a57/42b45186',
            width: 627,
            height: 382
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/55116cceb90fd5fcbbaafdf034701432/19f13682',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'recZjAPkstvyEgnB8'
  },
  {
    slug: 'info',
    img: [
      {
        id: 'att5UtPOgX419FXSW',
        url: 'https://dl.airtable.com/.attachments/761b53e1e887b78424cd9f5effe66174/da9f9ebc/info.png',
        filename: 'info.png',
        size: 18404,
        type: 'image/png',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/3dd3fbe7b5a9e1b41cf23c8ce43627dc/7655daa7',
            width: 36,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/a9a6af2e49eb87a0ac0151f01636b997/ca206599',
            width: 512,
            height: 512
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/914c337fd4ca954b007be8a97c2c01c7/2a6152f7',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'reccQR78arCi2jhuw'
  },
  {
    slug: 'risk',
    img: [
      {
        id: 'att9q0sV8IyHXSWUJ',
        url: 'https://dl.airtable.com/.attachments/c293bb33e22f8f376021c149a7f76df0/598ead4d/risk.png',
        filename: 'risk.png',
        size: 16420,
        type: 'image/png',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/4af559994777d7bd9f4b8e8e58661ff8/28614250',
            width: 39,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/105eee857757957b338445c85d3c2378/94abb091',
            width: 413,
            height: 381
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/0c866ba1a36a28eef701f3038ff8062c/a5c9b7dc',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'recjyJXiDBqB2cw1e'
  },
  {
    slug: 'standard',
    img: [
      {
        id: 'attTegSvtP6TFDba6',
        url: 'https://dl.airtable.com/.attachments/8d40176b4a33e75956f95aab30602055/a1ced8c4/941626.svg',
        filename: '941626.svg',
        size: 6673,
        type: 'image/svg+xml',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/509e87e3ec1f15c3df560e49fc852423/3569cc2c.png',
            width: 36,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/4a5f28b8270a0e910bfc6c4857be5b7a/434fc29e.png',
            width: 512,
            height: 512
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/9b7bfc70308146d9956b30ba849153c4/d52dc233.png',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'rec6UikKyK2Eb5jmI'
  },
  {
    slug: 'air-pressure-trigger',
    img: [
      {
        id: 'attj4f3smgzoqqeQB',
        url: 'https://dl.airtable.com/.attachments/e91bb318ae4ab4504b3e8178df22a305/ce31deb0/959711.png',
        filename: '959711.png',
        size: 9618,
        type: 'image/png',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/fc8e4e974e327008c9d4daf0cb839a27/56b4bff0',
            width: 44,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/1e5255668aaf536f28424d4b783637de/440d7d7a',
            width: 368,
            height: 304
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/8999e71b6dbcfb770f74ab90a879fc6b/1fe31c6b',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'recaBbgAuy7pWWlGd'
  },
  {
    slug: 'vibration-trigger',
    img: [
      {
        id: 'atteL57osardBrSNp',
        url: 'https://dl.airtable.com/.attachments/b2b3db41645cf7602dbb23d463e7d394/d19081fe/vib-2.png',
        filename: 'vib-2.png',
        size: 6872,
        type: 'image/png',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/32632ba4eb1dae1dfbb2537fb1c9e486/939169a3',
            width: 43,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/bc20c47ee672c8068b31f5ef7de6ec0e/cfd521ad',
            width: 221,
            height: 183
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/f501979c1ab71341a40b69e6a995ef04/6322dc3d',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'recag196JDti98F5l'
  },
  {
    slug: 'melbourne-heaven',
    img: [
      {
        id: 'attnRv49ilEj93u3Q',
        url: 'https://dl.airtable.com/.attachments/1267774108f5e6e21b602a0e1c4180fb/784827fc/Group3.png',
        filename: 'Group 3.png',
        size: 43043,
        type: 'image/png',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/200916ab80943202ca1041a7552445f2/49d8dfd8',
            width: 35,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/baf6fb692aa1e29dd7278c29efb586da/199d3087',
            width: 512,
            height: 525
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/6d4f8b561c00bf15803d961eb9a51b22/456eb025',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'recKd0YPuM7S7wlhe'
  },
  {
    slug: 'ny-heaven',
    img: [
      {
        id: 'attoyEEdNRDj0kjLo',
        url: 'https://dl.airtable.com/.attachments/134c9e653fb15f9bfabf0aa2875c13b5/4b2e48b3/2251310.png',
        filename: '2251310.png',
        size: 48824,
        type: 'image/png',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/3b6c1c2fabc1428678881b996ae92575/1af6bcfe',
            width: 36,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/f6391a6466d2201a4def660e160e7e35/1dbb6e36',
            width: 512,
            height: 512
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/6ef61eb8ec1b2cc666c94d699d9a7b57/e916e071',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'recs5v84SUMbKBOlB'
  },
  {
    slug: 'power-supply',
    img: [
      {
        id: 'attgOT8iMVqOfZkvr',
        url: 'https://dl.airtable.com/.attachments/8ac7e502de57a0aac3aa572c87774933/edf7217c/2188321.png',
        filename: '2188321.png',
        size: 20039,
        type: 'image/png',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/49a05a5f35b5200ee4ff14198a3a2578/f2261f27',
            width: 36,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/0aaeb6678b236484799af9b6b82a468f/08756535',
            width: 480,
            height: 480
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/ab93884fbd9cbbc62923ae3185fa62a9/26cb3640',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'recf054L54Vo5Ocz0'
  },
  {
    slug: 'museum',
    img: [
      {
        id: 'attdFnFv6TA7EDhDw',
        url: 'https://dl.airtable.com/.attachments/843f706b9139910a0e5c9cd2075c8a7e/a1965704/2150511.png',
        filename: '2150511.png',
        size: 19641,
        type: 'image/png',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/e57cc25b80567637392483553d62816d/e9fbaa97',
            width: 36,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/e668b716694f8f0ac2410670c1636e22/0297e4c0',
            width: 512,
            height: 512
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/f89634c1ab94b8156bbff5cf3b6ce514/b2e8798c',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'recFbAQrdkxmrzmPE'
  },
  {
    slug: 'cloud-admin',
    img: [
      {
        id: 'att1WNI6NUmLkJTWA',
        url: 'https://dl.airtable.com/.attachments/264a235a252bdabe476eaa21d7e7fb79/d88172ab/1388511.png',
        filename: '1388511.png',
        size: 38624,
        type: 'image/png',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/696808725e5d5e5450315c66ecc85e98/70829eb4',
            width: 36,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/71caa2a744d9ae6c7e8260c9ccefdd2d/9fe6b0c7',
            width: 512,
            height: 512
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/3e423e90c6a0cabc4751d43232038914/1eee5986',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'recQlEQUWr418neqy'
  },
  {
    slug: 'microphone',
    img: [
      {
        id: 'attoctsnw0CPpjHLh',
        url: 'https://dl.airtable.com/.attachments/f3dfa4c1e9962a1f474445b3ad61cdfc/e0b5bd2e/1976997.png',
        filename: '1976997.png',
        size: 15539,
        type: 'image/png',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/0ebaca60b066fa727a2eb211c8a2c52c/ef9a2f9f',
            width: 23,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/f7ac56945a75e81b9a6eca8d2e5cc2c2/2bc52062',
            width: 304,
            height: 480
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/020503f2af847f7f4a47df107eadf64c/858f97aa',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'rec7UNQZKkrvtJaYu'
  },
  {
    slug: 'affiliate',
    img: [
      {
        id: 'attTgktNjlk0U7xTg',
        url: 'https://dl.airtable.com/.attachments/bde77394425118da76b1772f58d2cfb7/e52537f4/2104031.png',
        filename: '2104031.png',
        size: 27006,
        type: 'image/png',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/b262d9d7ee9b966a64a695fda6ea776b/467e583d',
            width: 36,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/d04c5359a8b509c7588d25c48486b730/fd60c021',
            width: 480,
            height: 480
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/64f341bbb66d580893b9f82c1bf3d69d/b340f7f5',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'rec5jyrvu9Fura9MC'
  },
  {
    slug: '1-axis',
    img: [
      {
        id: 'att3HQ1YnWX3alVBw',
        url: 'https://dl.airtable.com/.attachments/c288d9ca355dd84f38a81e69d15c3a11/547361bd/1-axis.png',
        filename: '1-axis.png',
        size: 3021,
        type: 'image/png',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/3d7542ef7c88b8d0fa119925a17b9c90/3ccb7b8f',
            width: 54,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/0725fd0dafbf29e752a7d40adafb8eab/f0ee21c9',
            width: 249,
            height: 166
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/30c8541bd34018ff7592c6690fd61937/2ff76239',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'recXyMElQgq33Vvu7'
  },
  {
    slug: '2-axis',
    img: [
      {
        id: 'att4seLllcjRA23v6',
        url: 'https://dl.airtable.com/.attachments/b05d38c9388940f8ce04793b8b935648/06c3d56f/2-axis.png',
        filename: '2-axis.png',
        size: 4047,
        type: 'image/png',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/586d002fffb21f12eb7023fe86246344/55e4898c',
            width: 54,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/74ba5016860b44fc86b3b469d724b0f6/ce61de25',
            width: 249,
            height: 166
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/740c85b346959a5b92df7ae9f07e98c2/06aa728d',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'recWb4fXzKTurnhJV'
  },
  {
    slug: '3-axis',
    img: [
      {
        id: 'attYnvRY0OkamPut9',
        url: 'https://dl.airtable.com/.attachments/e8daffc282a360b5d72a18147d78f2dc/aa81e5d7/3-axis.png',
        filename: '3-axis.png',
        size: 5109,
        type: 'image/png',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/c6a1910d2a41b07ef94d023c47dd4392/d554414b',
            width: 54,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/eb9a6df1e602f4705f0c8ec867a7541a/7f36b768',
            width: 249,
            height: 166
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/42dc8f8d4d6966ea9417fe7cf587b43e/f9e8abfc',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'rec8IU3wt66v8rNjP'
  },
  {
    slug: 'string-potentiometer',
    img: [
      {
        id: 'att9uX14foxcPuMXA',
        url: 'https://dl.airtable.com/.attachments/df134444eee278f2799c290739b7a833/30508508/1724243.svg',
        filename: '1724243.svg',
        size: 1541,
        type: 'image/svg+xml',
        thumbnails: {
          small: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/24b60856451ffe91802413347bdfa371/8e2765ea.png',
            width: 36,
            height: 36
          },
          large: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/5ead3b58d8ae5f161c7bb6a404286534/69ff6c1d.png',
            width: 460,
            height: 460
          },
          full: {
            url: 'https://dl.airtable.com/.attachmentThumbnails/a8a3d9d80e3485c7f043eaab094e4edc/9db7e133.png',
            width: 3000,
            height: 3000
          }
        }
      }
    ],
    _id: 'reckxksotjHfDzCUR'
  }
]
