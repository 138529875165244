// https://docs.microsoft.com/en-us/rest/api/cosmos-db/querying-cosmosdb-resources-using-the-rest-api

import CryptoJS from 'crypto-js'

export const getCollectionDocs = ({ db, coll }) => {
  return `dbs/${db}/colls/${coll}/docs`
}

export const utcDate = date => {
  // return new Date(Date.now()).toUTCString()
  return new Date(date).toUTCString()
}

export const stripResource = route => {
  var [lst, pen, ...link] = route
    .trim()
    .split('/')
    .reverse()
  link = link.reverse()
  return link.length % 2 ? { type: lst, link: [...link, pen].join('/') } : { type: pen, link: [...link, pen, lst].join('/') }
}

export const getText = ({ method, timestamp, type, link }) => {
  /*
expected:
```
post
docs
dbs/db-main/colls/blastinghistory
thu, 19 sep 2019 16:07:32 gmt
```
*/
  return `${method || ''}\n${type || ''}\n${link || ''}\n${timestamp || ''}\n\n`.toLowerCase()
}

export const tokenFromSignature64 = base64Bits => {
  var MasterToken = 'master'
  var TokenVersion = '1.0'
  return encodeURIComponent('type=' + MasterToken + '&ver=' + TokenVersion + '&sig=' + base64Bits)
}

export const encryptSignature = ({ mastKey, text }) => {
  const key = CryptoJS.enc.Base64.parse(mastKey)
  const signature = CryptoJS.HmacSHA256(text, key)
  const base64Bits = CryptoJS.enc.Base64.stringify(signature)
  return tokenFromSignature64(base64Bits)
}

export const getRequestOptions = ({ host, db, collection, mastKey }) => {
  return ({ method, url, sql }) => {
    const route = url
      .replace('{{db}}', db)
      .replace('{{collection}}', collection)
      .replace(new RegExp('^https?://[^/]+/'), '')
    const { type, link } = stripResource(route)
    // partial
    return props => {
      const { date = Date.now() } = props || {}
      const timestamp = utcDate(date)
      const text = getText({ method, type, link, timestamp })
      const signature = encryptSignature({ text, mastKey })
      return { host, db, method, link, signature, timestamp }
    }
  }
}
