import React from 'react'

import { MULTI_TENANTS, ORGANISATION, RESIDENT } from './AppTypes'
// one of
//------------------------------
//------------------------------
let app = MULTI_TENANTS
//------------------------------
//------------------------------

const AppChooser = () => {
  let fragment = <div>No app defined</div>
  if (app === MULTI_TENANTS) {
    const App = require('./multi-tenants/App').default
    fragment = <App />
  }
  return fragment
}

export default AppChooser
