export const indexTable = itemsOrTable => {
  return Array.isArray(itemsOrTable) ? indexItems(itemsOrTable) : itemsOrTable
}
export const indexItems = items => {
  if (!Array.isArray(items)) {
    return {}
  }
  const ids = items.reduce((acc, { _id }, i) => {
    acc[_id] = i
    return acc
  }, {})
  const slugs = items.reduce((acc, { slug }, i) => {
    acc[slug] = i
    return acc
  }, {})
  return { items, ids, slugs }
}

export const getItemFromId = field => {
  return id => {
    if (!field.ids.hasOwnProperty(id)) {
      return null
    }
    const idx = field.ids[id]
    return field.items[idx]
  }
}

export const getItemFromSlug = field => {
  return slug => {
    if (!field || !field.slugs || !field.slugs.hasOwnProperty(slug)) {
      return null
    }
    const idx = field.slugs[slug]
    return field.items[idx]
  }
}

export const listItemsFromIds = (field, ids) => {
  const idGetter = getItemFromId(field)
  return (ids || []).map(idGetter)
}

export const slugSort = (a, b) => {
  const d1 = a.slug,
    d2 = b.slug
  return d1 > d2 ? 1 : d1 === d2 ? 0 : -1
}
export const i18nSort = (a, b) => {
  const d1 = a.i18n,
    d2 = b.i18n
  return d1 > d2 ? 1 : d1 === d2 ? 0 : -1
}
