import { requestForPost, fetchRequest } from 'domain-mmd/io-mmd/azure/fetch-cosmos'
import { airDefault, airDump } from 'domain-mmd/io-mmd/azure/signal/airDefault'
import { airWeekly } from 'domain-mmd/io-mmd/azure/signal/airWeekly'
import { vibRetrofitted } from 'domain-mmd/io-mmd/azure/signal/vibRetrofitted'
import { vibDefault, vibDump } from 'domain-mmd/io-mmd/azure/signal/vibDefault'
import { getRequestOptions } from 'domain-mmd/io-mmd/azure/buildBlastingRequest'
import moment from 'moment'

const isWeekly = timeRange => {
  const { start, end } = timeRange || {}
  const a = moment(start)
  const b = moment(end)
  const daysRange = a.diff(b, 'days')
  const isWeekly = daysRange <= -3 ? true : false
  return isWeekly
}

export const fetcher = (props, whenError, whenSuccess) => {
  const { timeRange, streamId } = props

  let dataFetcher
  // statements and formatter for signal type
  if (['lho-2-air'].includes(streamId)) {
    dataFetcher = isWeekly(timeRange) ? airWeekly : airDefault
  } else if (['lho-2-air__dump'].includes(streamId)) {
    dataFetcher = airDump
  } else if (['lho-1-vib'].includes(streamId)) {
    dataFetcher = { ...vibRetrofitted, partitionKey: '["10"]' }
  } else if (['pom-1-vib', 'tst-1-vib'].includes(streamId)) {
    dataFetcher = vibDefault
  } else if (['pom-1-vib__dump'].includes(streamId)) {
    dataFetcher = vibDump
  }

  // where to get the data from
  let coldPath
  if (['lho-2-air', 'lho-2-air__dump'].includes(streamId)) {
    coldPath = {
      host: 'db-main',
      db: 'db-main',
      mastKey: 'zceSSKpoUOGrbLD132oSqI0qAzjxOHvNZIxilB2MNZzDuwlqC1OB62SHCZksAkai8jCVFVkKV09dFcbe85r1NA==',
      collection: 'production'
    }
  } else if (['pom-1-vib', 'pom-1-vib__dump'].includes(streamId)) {
    coldPath = {
      host: 'db-main',
      db: 'db-main',
      mastKey: 'zceSSKpoUOGrbLD132oSqI0qAzjxOHvNZIxilB2MNZzDuwlqC1OB62SHCZksAkai8jCVFVkKV09dFcbe85r1NA==',
      collection: 'production',
      name: 'Railways'
    }
  } else if (['lho-1-vib'].includes(streamId)) {
    coldPath = {
      host: 'db-pr-202-01',
      db: 'pr-202-01-db',
      mastKey: 'TGaAlAwFpe0z8bLpicyxQ6w2HXfc9M6Y0t0Kycs6c09xXpLbdglo5kp8xNasItLtBJqEp5Y7tb9iaryEgy1spw==',
      collection: 'blastinghistory'
    }
  }

  const { statement, formatter, partitionKey } = dataFetcher || {}

  if (statement && formatter && coldPath) {
    const { host, db, mastKey, collection } = coldPath
    const body = statement(timeRange)
    const cosmos = getRequestOptions({
      host,
      db,
      collection,
      mastKey
    })({
      url: 'https://{{host}}.documents.azure.com:443/dbs/{{db}}/colls/{{collection}}/docs',
      method: 'POST'
    })
    const request = requestForPost(cosmos(), { body, itemCount: 1000000, partitionKey })
    fetchRequest(
      request,
      res => {
        formatter(res.Documents, ({ docs, data }) => {
          whenSuccess(data)
        })
      },
      err => {
        whenError(err)
      }
    )
  } else {
    whenError('We could not load the data')
  }
}
