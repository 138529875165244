import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
// Base
import { View, VSpacer } from 'design-system'
// Blocks
import AccountCard from 'blocks-mmd/account-card'
import { expandAccount } from 'domain-mmd/io-mmd/backoffice/utils'
import { tables } from 'domain-mmd/io-mmd/backoffice/tables'

const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  margin-left: -8px;
  margin-right: -8px;
`

const ListItem = styled.div`
  color: #4e4e4e;
  width: 280px;
  margin: 8px;
`
class AccountsExplorer extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { backofficeMgr, accounts, whenLens } = this.props
    if (!backofficeMgr) {
      return null
    }
    const fragments = accounts.map(d => {
      return (
        <ListItem key={d.slug}>
          <AccountCard {...{ backofficeMgr, item: d }} whenLens={whenLens} />
        </ListItem>
      )
    })
    return <List>{fragments}</List>
  }
}

AccountsExplorer.propTypes = {}

export default AccountsExplorer
