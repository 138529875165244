import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
// Base
import { View, Text, VSpacer, HSpacer } from 'design-system'
// Blocks
import Button from 'design-system/button'
import DateRangePicker from 'design-system/week-range-picker'
import Widget from 'blocks-mmd/widget/Widget'

const Block = styled.div`
  display: block;
  flex: 1;
`

const HBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const VBox = styled.div``

const Title = styled.div`
  font-size: 1.4em;
  font-weight: bold;
  margin-top: 32px;
`

const WidgetBox = styled.div`
  margin-top: 32px;
`

const NoWidgetDefined = () => {
  return <View>No Widget defined</View>
}

class StreamDashboard extends Component {
  constructor(props) {
    super(props)
    const { whenLoadStream } = this.props
    this.bound = {
      onLoadData: event => {
        const { item } = this.props
        const { streams } = item
        if (typeof whenLoadStream === 'function') {
          streams.map(d => {
            whenLoadStream(d.slug)
          })
        }
      }
    }
  }
  render() {
    const { item, timeRange, whenTimeRangeChange, whenExit, whenLoadStream, streamLastUpdate } = this.props
    const { onLoadData } = this.bound
    const { i18n: title, startDate, streams, streamsData } = item

    return (
      <Block>
        <View>
          <Button>
            {'<--'} <Text onClick={whenExit}>Back To Accounts</Text>
          </Button>
        </View>
        <Title>{title}</Title>
        <VSpacer height={8} />
        <HBox>
          <Button primary tokens={null} onClick={onLoadData}>
            Load Data
          </Button>
          <HSpacer width={16} />
          <VBox>
            <DateRangePicker whenChange={whenTimeRangeChange} timeRange={timeRange} firstDate={startDate} />
          </VBox>
        </HBox>
        <VSpacer height={8} />
        {(streams || []).map((d, i) => {
          const { slug, WidgetFactory } = d
          const streamData = streamsData[slug]
          return (
            <WidgetBox key={i}>
              <Widget data={streamData} Factory={WidgetFactory || NoWidgetDefined} />
            </WidgetBox>
          )
        })}
      </Block>
    )
  }
}

StreamDashboard.propTypes = {}

export default StreamDashboard
