import React, { Component } from 'react'
import styled from 'styled-components'

// Components

const Section = props => {
  const { label, /*icon,*/ name, prompt, whenChange } = props
  return (
    <SectionWrapper>
      <Label>{label}</Label>
      <Input {...{ name, prompt, whenChange }} />
    </SectionWrapper>
  )
}

const Hidden = props => {
  return <input type="hidden" name="csrfmiddlewaretoken" value="{secrets.token}" />
}

class Input extends Component {
  constructor(props) {
    super(props)
    this.onChange = event => {
      const v = event.currentTarget.value
      props.whenChange(v)
    }
  }
  render() {
    const { name, prompt } = this.props
    const { onChange } = this
    return (
      <label>
        <InputBox type={name} name={name} onChange={onChange} />
        <Toolitp>{prompt}</Toolitp>
      </label>
    )
  }
}

// Stylings
const Header = styled.div`
  display: block;
  padding: 8px 0;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  background: #fff;
  font-size: 16px;
  font-weight: 300;
  color: #232323;
  margin: 10px 14px 0;

  padding: 15px 13px;
  margin: 0;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.1);
  background: rgba(248, 248, 248, 0.9);
`

const FieldSet = styled.fieldset`
  display: block;
  padding: 25px 14px 5px;
  border: none;
  background: rgba(255, 255, 255, 0.9);
  position: relative;

  & + fieldset {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
`

const SectionWrapper = styled.section`
  margin-bottom: 15px;
  position: relative;
`

const SvgIcon = ({ a11yTitle, color, size, ...rest }) => <svg aria-label={a11yTitle} {...rest} />
SvgIcon.displayName = 'Icon'

const Button = styled.button`
  cursor: pointer;
  float: ${props => (props.rightAlign ? 'right' : 'none')}
  height: 31px;
  font: 300 15px/29px 'Open Sans', Helvetica, Arial, sans-serif;
  cursor: pointer;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin: 10px 0 0 5px !important;
  padding: 0px 22px !important;
  /*
  color: ${props => (props.primary ? 'blue' : 'gray')};
  border: ${props => (props.primary ? '2px solid blue' : '2px solid gray')};
  transition: 0.5s all ease-out;
  &:hover {
    color: white;
    background-color: ${props => (props.primary ? 'blue' : 'gray')};
  }
  */
`
const Actions = styled.section`
  display: block;
  padding: 7px 14px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(248, 248, 248, 0.9);

  &:after {
    content: '';
    display: table;
    clear: both;
  }
`

export const Toolitp = styled.b`
  bottom: 30px;
  position: absolute;
  z-index: 99999;
  left: -9999px;
  padding: 2px 8px 3px;
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  opacity: 0;
  transition: margin 0.3s, opacity 0.3s;
  -o-transition: margin 0.3s, opacity 0.3s;
  -ms-transition: margin 0.3s, opacity 0.3s;
  -moz-transition: margin 0.3s, opacity 0.3s;
  -webkit-transition: margin 0.3s, opacity 0.3s;

  &:after {
    top: 100%;
    right: 11px;
    border-top: 4px solid rgba(0, 0, 0, 0.9);
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    content: '';
    position: absolute;
  }

  .input input:focus + &,
  .textarea textarea:focus + & {
    right: 0;
    left: auto;
    margin-bottom: 5px;
    opacity: 1;
  }
`

export const InputBox = styled.input`
  position: relative;
  display: block;
  font-weight: 400;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  height: 32px;
  line-height: 32px;
  padding: 5px 10px;
  outline: 0;
  border-width: 1px;
  border-style: solid;
  border-color: #cccccc;
  border-radius: 0;
  background: #fff;
  font: 13px/16px 'Open Sans', Helvetica, Arial, sans-serif;
  color: #404040;
  appearance: normal;
  -moz-appearance: none;
  -webkit-appearance: none;
`
const Label = styled.label`
  display: block;
  margin-bottom: 6px;
  line-height: 19px;
  font-weight: 400;
  font-size: 13px;
  color: #333;
  text-align: left;
  white-space: normal;
`

const Wrapper = styled.form`
  margin: 0;
  outline: 0;
  color: #666;
  position: relative;

  & *,
  &:after,
  &:before {
    margin: 0;
    padding: 0;
    box-sizing: content-box;
    -moz-box-sizing: content-box;
  }

  .input input,
  .select select,
  .textarea textarea {
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;
    height: 32px;
    line-height: 32px;
    padding: 5px 10px;
    outline: 0;
    border-width: 1px;
    border-style: solid;
    border-radius: 0;
    background: #fff;
    font: 13px/16px 'Open Sans', Helvetica, Arial, sans-serif;
    color: #404040;
    appearance: normal;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
`

const Form = { Wrapper, Actions, Section, Header, FieldSet, Hidden, Button, InputBox }

export default Form
