import React from 'react'
import logo from './etc/top-footer.png'

import styled from 'styled-components'
import Thirds from 'design-system/group-segments/segments-thirds'
const Footer = styled.footer`
  background-color: #0e2230;
  color: #ffffff;
  padding-bottom: 32px;
  height: auto;
`

const Section = styled.div`
  text-align: left;
  box-sizing: border-box;
  text-align: center;
  margin-top: 8px;
  a {
    color: #ffffff;
    text-decoration: none;
  }
  ul,
  li {
    margin: 0;
    padding: 0 0 0 0;
    list-style-type: none;
  }
  li {
    padding: 0 0 0 1em;
  }
`

/*
const SectionTitle = styled.div`
  color: #b0d1da;
  font-weight: 700;
  font-size: 1.2em;
  text-transform: uppercase;
  letter-spacing: 1px;
`
*/
const Separator = styled.img`
  margin-top: -90px;
`

const RecovibBlah = <Section>Powered by Micromega Dynamics</Section>

const SiteFooter = props => {
  return (
    <Footer>
      <Separator src={logo} width="100%" alt="footer separator" />
      <Thirds>
        <Thirds.i />
        <Thirds.i>{RecovibBlah}</Thirds.i>
        <Thirds.i />
      </Thirds>
    </Footer>
  )
}

SiteFooter.propTypes = {}

export default SiteFooter
