import { connect } from 'react-redux'

const connector = connect(
  state => {
    const { backofficeMgr, accounts, affiliation } = state
    return { backofficeMgr, accounts, affiliation }
  },
  (dispatch, ownProps) => {
    return {
      whenLens: slug => {
        dispatch({ type: 'LENS_ACTIVE_CHANGE', payload: { slug } })
      }
    }
  }
)

export default connector
