import React from 'react'

const ModyvaLogo = props => {
  const { /*noLink,*/ height, branding } = { branding: 'micromega', ...props }
  return (
    <a href="https://micromega-dynamics.com/products/recovib/miniature-vibration-recorder/">
      <img src={`https://assets.recovib.io/affiliate-branding/${branding}__masthead-logo.png`} height={height} alt="affiliate logo" />
    </a>
  )
}

export default ModyvaLogo
