const isFunction = fn => {
  return typeof fn === 'function'
}

export const requestForPost = ({ method, signature, timestamp, link, host, port = '443' }, { itemCount = 10, partitionKey = '["99"]', body }) => {
  return {
    url: `https://${host}.documents.azure.com:${port}/${link}/docs`,
    method: method,
    timeout: 0,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/query+json',
      'x-ms-version': '2018-12-31',
      Authorization: signature,
      'x-ms-date': timestamp,
      'x-ms-max-item-count': itemCount !== -1 ? itemCount : undefined,
      'x-ms-documentdb-populatequerymetrics': 'true',
      'x-ms-documentdb-isquery': 'true',
      'x-ms-documentdb-query-enablecrosspartition': 'true',
      'x-ms-documentdb-partitionkey': partitionKey
    },
    body: body ? JSON.stringify(body) : undefined
  }
}

export const fetchRequest = (request, whenSuccess, whenError) => {
  fetch(request.url, request)
    .then(res => {
      if (!res.ok) {
        const error = `NOTOK - error ${JSON.stringify(res)}`
        if (isFunction(whenError)) {
          whenError({ error })
        } else {
          throw error
        }
      }
      return res.json()
    })
    .then(response => {
      if (isFunction(whenSuccess)) {
        // console.log('response', JSON.stringify(response))
        whenSuccess(response)
      } else {
        console.log('Success:', JSON.stringify(response))
      }
    })
    .catch(error => {
      if (isFunction(whenError)) {
        whenError({ error })
      } else {
        console.error('Error:', error)
      }
    })
}

/*
AJAX - deprecated
export const fetchQuery = ({ method = 'PUT', url, data, headers, onResponse, onError }) => {
  const defaultHeaders = {
    'Content-Type': 'application/json'
  }
  fetch(url, {
    method: method.toUpperCase(),
    body: JSON.stringify(data),
    headers: headers || defaultHeaders
  })
    .then(res => res.json())
    .then(response => console.log('Success:', JSON.stringify(response)))
    .catch(error => console.error('Error:', error))
}

var settings = {
  "url": "https://{{DocumentDBHost}}.documents.azure.com:443/dbs/{{DatabaseId}}/colls/blastinghistory/docs",
  "method": "POST",
  "timeout": 0,
  "headers": {
    "Accept": "application/query+json",
    "x-ms-version": "2018-12-31",
    "Authorization": "{{authToken}}",
    "x-ms-date": "{{RFC1123time}}",
    "x-ms-documentdb-isquery": "true",
    "Content-Type": "application/query+json",
    "x-ms-documentdb-query-enablecrosspartition": "true",
    "x-ms-documentdb-partitionkey": "[\"13\"]",
    "x-ms-max-item-count": "10"
  },
  "data": "{   \r\n    \"id\": \"99\",\r\n    \"query\": \"select r.device.channels from r where(r.device.metadata.msg_dt between @start  and @end ) ORDER BY r.device.metadata.msg_dt desc\",\r\n    \"parameters\": [{\"name\": \"@start\",\"value\": \"2016-09-05T12:57:00\"},{\"name\": \"@end\",\"value\": \"2019-09-17T12:57:00\"} ] \r\n}",
};

$.ajax(settings).done(function (response) {
  console.log(response);
});
*/
