import moment from 'moment'

const statement = timeRange => {
  const { start, end } = timeRange
  return {
    id: '99',
    query:
      'select r.acq_dt, r.peak_data_summary as peak, r.n_stats, r.p_stats, r.virtual_channel_units, r.name  from r where (r.msg_dt between @start  and @end ) AND r.tlm_type = @tlm AND r.name = @name ORDER BY r.msg_dt desc',
    parameters: [{ name: '@start', value: start }, { name: '@end', value: end }, { name: '@tlm', value: 4 }, { name: '@name', value: 'Air fast' }]
  }
}
const formatter = (docs, asyncReturn) => {
  const data = docs.reduce((acc, d, i) => {
    const timestamp = d.acq_dt
    const time = moment(timestamp).toISOString()
    if (d.peak) {
      acc.push({ v: d.peak, time, timestamp, i })
    }
    return acc
  }, [])

  asyncReturn({ data, docs })
}

export const airWeekly = { statement, formatter }
