export const affiliations = [
  {
    i18n: 'Micromega',
    'accounts copy':
      'ny-heaven-rails__1__condition-monitoring, gsm-pylon-structural-integrity__1__structural-integrity, wind-turbine__1__structural-integrity, cooling-system__1__condition-monitoring, pagoda__1__structural-integrity, pommeroeul-railways__1__construction-vibs',
    slug: 'micromega',
    accounts: ['recCKqe7MXx7JVBv5', 'recwg1YVktabydyS6', 'recJobaZWqkus6d5p', 'rectiQruVVAX6TTjz', 'recmCJ0zwOsnoNNzv', 'rec5xAq6CClE4T2oE'],
    roles: ['rec4x5m6sX9WitcjV'],
    _id: 'recXTMFAyaZHCCqHD'
  },
  {
    i18n: 'Modyva',
    'accounts copy':
      'lhoist-quarry__1__blasting, bierset-ground-radar__1__construction-vibs, thomas-piron-resident__resident-1__construction-vibs, ec-museum__1__construction-vibs+construction-dust, edith-cavel-hospital__1__construction-vibs',
    slug: 'modyva',
    accounts: ['rec1KXFJWYqisujda', 'recga2zwUMbFlh4yv', 'reckaKC5QcyUbTXQa', 'reciy0qk1uAodJbNq', 'recTOr2J3qqBwmDGS'],
    roles: ['recWhESvBQhWAoKWJ'],
    _id: 'recvWEyI3yT9BW4Uj'
  },
  {
    i18n: 'Lhoist',
    slug: 'lhoist',
    roles: ['rec86csWr6koH1yzo', 'recv4PY3DyhlXjs2O'],
    _id: 'recP7Ox8KIRghX2Bd'
  },
  {
    i18n: 'Galere',
    slug: 'galere',
    roles: ['recTuvPlNvLR47YAY'],
    _id: 'recbBN1QL5PXV7C5Z'
  },
  {
    i18n: 'Thomas Piron',
    slug: 'thomas-piron',
    roles: ['rec9PlnfOyxJLesXG', 'recbUdI4S2SQO6fSG'],
    _id: 'recMpGTUdRYlvc6cz'
  },
  {
    i18n: 'European Commission Museum',
    slug: 'euro-com-museum',
    roles: ['rec3zjBSl3supVrdH'],
    _id: 'rec8azrVFEcZMZy8M'
  },
  {
    i18n: 'Edit Cavell Hospital',
    slug: 'edith-cavell-hospital',
    roles: ['recrQQPCOkPQ1LN6z'],
    _id: 'recvWTkw5jCtRMHvT'
  },
  {
    i18n: 'Wallib Holland',
    slug: 'walibi-holland',
    roles: ['recOOm0n4gxUVws2d'],
    _id: 'reco2T4qY8k7ruPdZ'
  },
  {
    i18n: 'Flow Engineering',
    'accounts copy': 'walibi-nl-roller-coaster__1__damping-tmd, flagpole__1__structural-integrity+damping-tmd',
    slug: 'flow-engineering',
    accounts: ['reclxrL0Cup5N6Pr0', 'recwnCRazGSgCOjxj'],
    roles: ['recAXFZv1FVRR7zjf'],
    _id: 'rec7IkhJXt0UCI4gy'
  },
  {
    i18n: 'Flagpole',
    slug: 'flagpole',
    roles: ['recuH54acD7GGz1VT'],
    _id: 'recwTyR80oQHe691F'
  },
  {
    i18n: 'Gantrex',
    'accounts copy': 'melb-heaven-rails__1__condition-monitoring',
    slug: 'gantrex',
    accounts: ['recLyFFSsyqhuR7LE'],
    roles: ['rec5P6GryMhqOLxuv'],
    _id: 'recmwl0uYaUKl61Rj'
  },
  {
    i18n: 'Melbourne Heaven',
    slug: 'melbourne-heaven',
    roles: ['recj7ejjLSYcUwDwS'],
    _id: 'recD5Wxo1tG9MJGJP'
  },
  {
    i18n: 'New York Heaven',
    slug: 'new-york-heaven',
    roles: ['recZ1rJcUAHhvm8oQ'],
    _id: 'rec5Dr2AzT38TCRlA'
  },
  {
    slug: 'pommeroeul',
    roles: ['recAf0M5Ai2UaIRYy'],
    _id: 'recO1AJ5fGJgnMkaV'
  }
]
