import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { i18nSort } from 'domain-mmd/io-mmd/backoffice/utils'
import { tables } from 'domain-mmd/io-mmd/backoffice/tables'
import AccountLensSummary from 'blocks-mmd/account-lens-summary'

const Card = styled.div`
  font-family: 'Calibri', sans-serif;
  position: relative;
  padding: 16px;
  padding-top: 16px;
  box-sizing: border-box;
  box-shadow: 0 0.25rem 1rem rgba(48, 55, 66, 0.15);
  /*  border: 1px solid #eaeaea; */
  font-size: 12pt;
  line-height: 1.2;
  width: 100%;
  height: 100%;
`

const Head = styled.div`
  display: block;
  min-height: 72px;
`
const Title = styled.div`
  font-family: 'GothamBold', sans-serif;
  display: block;
  font-size: 1.2em;
  margin-bottom: 4px;
  line-height: 0.95;
`

const LensTitle = styled.div`
  font-family: 'Calibri', sans-serif;

  display: block;
  line-height: 1;
  font-size: 1.1em;
  font-weight: 500;
`

const City = styled.div`
  font-family: 'Calibri', sans-serif;
  display: block;
  font-size: 0.9em;
  color: #4d6a79;
  margin-bottom: 4px;
  font-weight: 500;
`

const ContactName = styled.div`
  display: block;
  font-size: 0.9em;
  color: gray;
  margin-bottom: 4px;
`
const AccountStatus = styled.div`
font-family: 'GothamBold', sans-serif;
display: block;
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${props => {
    const clrs = { active: 'green', closed: 'gray', canceled: 'red' }
    return clrs[props.status] || 'gray'
  }};;
  color: white
  padding: 4px 8px 2px 8px;
  font-size: 0.5em;
`

const Short = styled.div`
  color: gray;
  font-style: italic;
`

const HBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > * {
  }
`
const ProfileImage = styled.img`
  display: block;
  max-height: 128px;
  margin-bottom: 16px;
`
const List = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
`

const Item = styled.div`
  box-sizing: border-box;
  width: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 4px;
  margin-top: 8px;
`
class AccountActions extends Component {
  // <ContactName>Admins: {accountName}</ContactName>
  render() {
    return <div>ACTIONS TO TAKE</div>
  }
}

class AccountCardExplorer extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { item, backofficeMgr, whenLens } = this.props
    let fragment = null
    if (item) {
      const { slug, i18n, short_i18n, lenses, location_i18n, status } = item

      const expandedLenses = backofficeMgr.expand(tables.LENSES)(lenses)
      const image = slug || ''
      fragment = (
        <Card>
          <Head>
            <AccountStatus status={status}>{status.toUpperCase()}</AccountStatus>
            <Title>{i18n}</Title>
            <City>{location_i18n}</City>
          </Head>
          <HBox>
            <ProfileImage src={`https://assets.recovib.io/accounts/${image}.png`} />
            <List>
              {(expandedLenses || []).sort(i18nSort).map(d => {
                return (
                  <Item key={d.slug}>
                    <LensTitle>{d.i18n}</LensTitle>
                    <AccountLensSummary {...{ item: d, backofficeMgr, whenLens }} />
                  </Item>
                )
              })}
              {/* Actions a prendre en cas de probleme */}
              {/* <AccountActions /> */}
            </List>
          </HBox>
        </Card>
      )
    }
    return fragment
  }
}

export default AccountCardExplorer
